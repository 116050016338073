.uploadDiv {
    width: 80%;
}

.uploadDiv label {
    justify-content: center;
    text-transform: none;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-color: #d3d3d3;
    color: var(--theme);
}

.uploadDiv label:hover {
    border-color: var(--light-theme) !important;
}
