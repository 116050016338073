.transition1s {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
/* =============================== */
@media (min-width: 1450px) {
  .containerHead {
    max-width: 1140px;
  }
}
.imgExternalLink {
  margin-left: 5px;
}

.headTopMenu.topheadNabar {
  position: fixed;
  left: 0;
  top: -100%;
  width: 100%;
  z-index: 100;
  background-color: #000000;
  box-shadow: none;
  height: 65px;
  overflow: hidden;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-flex-shrink: initial;
  -ms-flex-negative: initial;
  flex-shrink: initial;

}
.headTopMenu.topheadNabar.whiteTheme {
  background-color: #fff;
}
.topActive .headTopMenu.topheadNabar,
.allActive .headTopMenu.topheadNabar,
.allPage.headTopMenu.topheadNabar {
  top: 0;
}
.headTopMenu .menuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-left: -15px;
  margin-right: -15px;
}
.allPage.headTopMenu .menuWrapper {
  margin-left: -12px;
  margin-right: -12px;
}
.toolbar {
  max-width: 100%;
  flex: 0 0 100%;
}

.menuHead ul,
.menuRight ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  flex: 2 1;
  position: relative;
  font-family: 'Oswald', sans-serif;
}
.menuHead {
  width: 100%;
  max-width: 665px;
}
.menuHead ul {
  position: relative;
  overflow: hidden;
}
.menuHead ul::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -50px;
  width: 110px;
  height: 35px;
  background-color: #3a7cab;
  border-radius: 35px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  visibility: hidden;
}
.activeWhatIs .menuHead ul::after,
.activeFeature .menuHead ul::after,
.activeValue .menuHead ul::after,
.activeNews .menuHead ul::after,
.activeContact .menuHead ul::after {
  opacity: 1;
  bottom: 5px;
  visibility: visible;
}
.activeWhatIs .menuHead ul::after {
  left: 0;
}
.activeFeature .menuHead ul::after {
  left: calc(16.6666% * 1);
}
.activeValue .menuHead ul::after {
  left: calc(16.6666% * 2);
}
.activeNews .menuHead ul::after {
  left: calc(16.6666% * 3);
}
.activeContact .menuHead ul::after {
  left: calc(16.6666% * 4);
}
.menuHead ul li {
  flex: 2 1;
  position: relative;
  z-index: 5;
}
.menuHead ul li a,
.menuRight ul li a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.whiteTheme .menuHead ul li a,
.whiteTheme .menuRight ul li a:not(.btnContact) {
  color: #000000;
}
.menuRight ul li a {
  color: #fff;
}
.menuHead ul li a {
  font-size: 16px;
  height: 45px;
  width: 100%;
  position: relative;
}

.menuRight ul li:not(:last-child) {
  margin-right: 37px;
}
.menuRight ul li a {
  font-size: 14px;
}
.menuRight ul li:last-child a {
  height: 40px;
  width: 130px;
  background: rgb(38, 161, 155);
  background: linear-gradient(95deg, rgba(38, 161, 155, 1) 0%, rgba(53, 165, 212, 1) 100%);
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
}

@media (max-width: 1199px) {
  .menuHead {
    width: 605px;
  }
  .menuHead ul::after {
    width: 100px;
  }
}
@media (max-width: 991px) {
  .containerHead {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .menuHead {
    width: calc(100% - 120px - 182px);
  }
  .menuRight ul li:last-child a {
    width: 110px;
    font-size: 12px;
  }
  
}
@media (max-width: 899px) {
  .menuHead,
  .menuRight{
    display: none;
  }
  .containerHead .navMobile{
    flex-grow: initial;
  }
  .logoHead{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .navMobile button{
    padding: 0;
  }
  .navMobile svg{
    width: 30px;
    height: 30px;
  } 
  .navMobile svg path{
    color: #fff;
  }
  .headTopMenu.topheadNabar.whiteTheme{
    background-color: #000000;
  }
}
@media (max-width: 575px) {
  .headTopMenu.topheadNabar{
    height: 64px;
  }
  .headTopMenu .menuWrapper{
    justify-content: initial;
    padding: 15px 0;
  }
}