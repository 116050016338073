.container {
  padding: 0px;
  margin-top: -75px;
}
.greeting {
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 430px) {
}
