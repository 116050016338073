.drawerContent {
  position: fixed;
  right: 0;
  top: 0;
  width: 25vw;
  height: 100%;
  background-color: white;
  z-index: 1001;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 35px 20px; 
  display: flex;
  flex-direction: column;
}

@media (max-width: 920px){
  .drawerContent {
    position: fixed;
    right: 0;
    top: 0;
    width: 45vw;
    height: 100%;
    background-color: white;
    z-index: 1001;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 35px 20px; 
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 450px){
  .drawerContent {
    position: fixed;
    right: 0;
    top: 0;
    width: 75vw;
    height: 100%;
    background-color: white;
    z-index: 1001;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 35px 20px; 
    display: flex;
    flex-direction: column;
  }
}

.color-theme {
  font-size: 24px;
  color: var(--theme);
  font-weight: bold;
}
.subTitle .btn-color-theme {
  color: var(--theme);
}
.subTitle {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 48.5px;
} 
.drawer-filename,
.drawer-listing {
  display: flex;
  flex-direction: column;
}

.drawer-filename input,
.drawer-listing input {
  padding: 0px;
  height: fit-content;
}
.drawer-listing {
  flex-grow: 1;
  overflow-y: auto
}
.drawer-sub-title {
  font-family: Oswald;
  font-size: 15px;
  font-weight: 700;
  line-height: 22.23px;
  text-align: left;
  padding-top: 1rem;
}
.list-item li {
  height: fit-content;
}

p.fileName {
  padding-left: 0px !important;
  height: 40px;
}
.questionItem {
  height: 40px;
  padding-left: 0px !important;
}
.drawerContent .drawerButton {
  height: 55px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 15px !important;
  color: white !important;
  background-color: var(--theme);
}

.drawerContent .drawerButton:hover {
  background-color: var(--light-theme);
}
.closeButton {
  border: none;
  cursor: pointer;
  padding: 35px 0px;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  color: var(--theme);
}