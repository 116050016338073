:root {
  --color-white: #fafafa;
  --theme: #3a7cab;
  --light-theme: #7cb1d9;
  --bg-login-theme: url('@/assets/images/bg-login.svg');
  --bg-unauthen-theme: url('@/assets/images/bg-unauthen-blue.svg');
  --search-icon-theme: url('@/assets/icons/search-blue.svg');
  --border-bottom-color: #cecece;
  background-color: #fafafa;
}

@font-face {
  font-family: 'PTSans';
  src:
    local('PTSans'),
    url(./assets/fonts/PTSans/PTSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PTSans';
  font-weight: 900;
  src:
    local('PTSans'),
    url(./assets/fonts/PTSans/PTSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter'),
    url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src:
    local('Inter'),
    url(./assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src:
    local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  src:
    local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 700;
  src:
    local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 900;
  src:
    local('Oswald'),
    url(./assets/fonts/Oswald/Oswald-Bold.ttf) format('truetype');
}
body,
html {
  margin: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root,
.app-container {
  mix-blend-mode: initial;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  border-style: none;
}

button.MuiButton-root,
button.MuiButton-root:hover,
button.MuiButton-root:active,
button.MuiButton-root:focus-visible {
  box-shadow: none;
}

input.color-placeholder::placeholder {
  color: #fff;
}

input.MuiInput-input,
textarea.MuiInput-input {
  height: 65px;
  padding-left: 30px;
}
.MuiInputBase-multiline {
  min-height: 65px;
}
ul {
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.mid-container {
  padding: 0px 68px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper {
  padding: 0px 50px;
}

.img-logo {
  z-index: 2;
}

a.dropdown-item.active,
a.dropdown-item:active {
  color: #fff;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5c5c5;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #c5c5c5;
  border-radius: 6px;
}

.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 860px;
  margin: auto;
  text-align: center;
}

.not-found img {
  width: auto;
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}

.not-found a {
  font-weight: 600;
  text-decoration: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

/* overwrite styles lib */
.rbt-close-content {
  display: none;
}

div.custom-toast {
  max-width: fit-content;
}

#sidebar-positioned-menu .MuiPaper-root {
  bottom: 70px;
  top: unset !important;
  left: 0 !important;
  transition: none !important;
}

span.MuiSkeleton-root {
  margin-bottom: 15px;
  border-radius: 16px;
}

#root .MuiInput-root.Mui-disabled:before {
  border-bottom-style: solid;
}

/* #root .Mui-disabled {
  background: rgba(224, 224, 224, 0);
} */

nav.rdt_Pagination {
  border-top: none;
}

.MuiAutocomplete-popper .MuiAutocomplete-option:hover {
  background-color: var(--theme);
}

.MuiInputBase-root.MuiInput-root:before {
  border-bottom-color: var(--border-bottom-color);
}

.slickSlider .slick-dots {
  bottom: -58px;
}

.slickSlider .slick-dots li {
  margin: 0 6px;
}

.slickSlider .slick-dots li,
.slickSlider .slick-dots li button {
  padding: 0;
  width: 10px;
  height: 10px;
}

.slickSlider .slick-dots li button::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #838383;
  border-radius: 20px;
  opacity: 1;
}

.slickSlider .slick-dots li.slick-active button:before {
  background-color: var(--theme);
  opacity: 1;
}

tr.p-rowgroup-header:first-child > td {
  padding-top: 0;
}

.title-page-table {
  flex: 1 1;
  font-size: 1.125rem;
  font-weight: 600;
}

.header-page-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid #c1c1c1;
}

.box-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: var(--theme);
  top: 0;
  right: 0;
  width: auto;
  height: 56px;
  padding: 15px 0px;
  border-bottom-left-radius: 10px;
  z-index: 2;
  max-width: 360px;
}

.box-actions.drawerOpen {
  right: 25%;
}

.box-actions ul {
  display: flex;
}

.box-actions ul li {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  text-align: center;
  height: 40px;
  padding: 0px 15px;
  position: relative;
}

.box-actions ul li:not(:last-child)::before {
  position: absolute;
  content: '';
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 22px;
  background-color: #fff;
  border-radius: 16px;
}

.box-actions ul li:last-child {
  border-right: none;
}

.box-action {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 90px;
  padding: 0px 10px;
  border-radius: 43px;
}

.box-action:hover {
  cursor: pointer;
}

.box-action img {
  margin-right: 10px;
}

.box-action p {
  margin: 0;
  font-weight: 900;
  font-size: 0.875rem;
  color: #fff;
}

.menuHead ul::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -50px;
  width: 110px;
  height: 35px;
  background-color: #3a7cab;
  border-radius: 35px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  visibility: hidden;
}
.active-whatIs .menuHead ul::after,
.active-feature .menuHead ul::after,
.active-value .menuHead ul::after,
.active-news .menuHead ul::after,
.active-contact .menuHead ul::after {
  opacity: 1;
  bottom: 5px;
  visibility: visible;
}
.active-whatIs .menuHead ul::after {
  left: 10px;
}
.active-feature .menuHead ul::after {
  left: calc(20% * 1 + 10px);
}
.active-value .menuHead ul::after {
  left: calc(20% * 2 + 10px);
}
.active-news .menuHead ul::after {
  left: calc(20% * 3 + 10px);
}
.active-contact .menuHead ul::after {
  left: calc(20% * 4 + 10px);
}
@media (max-width: 1200px) {
  body,
  html {
    font-size: 13px;
  }

  input.MuiInputBase-input {
    font-size: 1.2rem;
  }

  button.MuiButton-root {
    font-size: 1rem;
  }

  p.MuiFormHelperText-root {
    font-size: 0.923rem;
  }
}
.wrapperMenuSP {
  display: none;
}
@media (max-width: 899px) {
  .wrapperHMenu .button__hamburger {
    position: relative;
    width: 60px;
    height: 33px;
    min-width: auto;
  }
  .button__hamburger::after {
    position: absolute;
    content: '';
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% + 6px);
    height: 64px;
    background-color: #fafafa;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease;
  }
  .button__hamburger.toggle::after {
    opacity: 1;
    visibility: visible;
  }
  .button__hamburger span {
    position: absolute;
    width: 23px;
    height: 1px;
    background-color: #fafafa;
    border-radius: 4px;
    left: calc(50% - 11.5px);
    transition: all 0.3s ease;
  }
  .button__hamburger span:last-child {
    display: none;
  }
  .button__hamburger span:nth-child(3n + 1) {
    top: 10px;
  }
  .button__hamburger span:nth-child(3n + 2) {
    top: 17px;
  }
  .button__hamburger span:nth-child(3n + 3) {
    top: 24px;
  }
  .button__hamburger.toggle span {
    background-color: #000000;
  }
  .button__hamburger.toggle span:nth-child(3n + 2) {
    opacity: 0;
  }
  .button__hamburger.toggle span:nth-child(3n + 1) {
    rotate: 45deg;
    top: 15px;
  }
  .button__hamburger.toggle span:nth-child(3n + 3) {
    rotate: 315deg;
    top: 15px;
  }
  /* == */
  .wrapperMenuSP {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    display: block;
    background-color: #fff;
    z-index: -1;
    transition: all 0.6s ease;
    visibility: hidden;
    padding: 20px 15px;
    opacity: 0;
    background-image: url('../src/assets/images/top/bg-banner.svg');
    background-size: 200%;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  .wrapperMenuSP.activeMM {
    opacity: 1;
    visibility: visible;
  }
  .wrapperMenuSP .mmenuBody {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .wrapperMenuSP .mmenuBody .mmMenuUL {
    position: relative;
    margin-bottom: 10px;
    transition: all 0.6s ease;
    padding-left: 45px;
  }
  .wrapperMenuSP.activeMM .mmenuBody .mmMenuUL {
    padding-left: 0;
  }
  .mmenuBody .mmMenuUL li {
    border-bottom: 1px solid #dfdfdf;
  }
  .mmenuBody .mmMenuUL li a {
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 60px;
    font-weight: bold;
    font-size: 15px;
    color: #000000;
    text-decoration: none;
  }

  .mmMenuBottom {
    transition: all 0.6s ease;
    transform: translateY(45px);
    margin-bottom: 29px;
  }
  .activeMM .mmMenuBottom {
    transform: translateY(0px);
  }
  .mmMenuBottom li:not(:last-child) {
    margin-bottom: 5px;
  }
  .mmMenuBottom li a {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    text-decoration: none;
    height: 60px;
    padding-left: 15px;
  }
  .mmMenuBottom li:first-child a {
    background-color: #000000;
  }
  .mmMenuBottom li:last-child a {
    background: #26a19b;
    background: linear-gradient(95deg, #26a19b, #35a5d4);
  }
  .mmMenu__Footer .mmMenu__Logo {
    border-bottom: 1px solid #CACACA;
    padding-bottom: 18px;
  }
  .mmMenu__Footer .mmLogo img{
    width: 130px;
  }
  .mmMenu__Footer .mmMenuFooterUL{
    padding: 10px 0 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #CACACA;
  }
  .mmMenuFooterUL li:not(:last-child){
    margin-bottom: 15px;
  }
  .mmMenuFooterUL li.liBorderTopBottom{
    border-top: 1px solid #CACACA;
    border-bottom: 1px solid #CACACA;
    padding: 10px 0;
  }
  .mmMenuFooterUL li a{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 100%;
    color: #434343;
    font-size: 14px;
    text-decoration: none;
    font-family: "Oswald", sans-serif;
  }
  .mmLogoFinch{
    margin-bottom: 27px;
  }
  .mmCopyright{
    margin: 0 0 15px;
    text-align: center;
    color: #004EA2;
    font-size: 12px;
    font-family: "Oswald", sans-serif;
  }
}
@media (max-width: 780px) {
  body,
  html {
    font-size: 10px;
  }

  input.MuiInputBase-input {
    font-size: 1.4rem;
  }

  button.MuiButton-root {
    font-size: 1.3rem;
  }

  p.MuiFormHelperText-root {
    font-size: 1rem;
  }

  .box-action p {
    display: none;
  }

  .box-action img {
    margin: 0 5px;
  }

  .box-actions ul li,
  .box-action {
    width: auto;
  }
}
@media (max-width: 650px) {
  .mid-container {
    padding: 45px 10px 0px 10px;
  }
}
@media (max-width: 575px) {
  .active-whatIs .logo-top,
  .active-feature .logo-top,
  .active-value-detail .logo-top,
  .active-news-detail .logo-top {
    display: none;
  }
}

tr.row-unselected {
  border-radius: 0;
}

tr.row-unselected td.colSelected { 
  background-color: var(--theme);
  position: relative;
  width: 110px;
  padding: 0;
}

tr.row-unselected td.colSelected button {
  background-color: var(--theme);
  color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

tr.row-selected {
  border-radius: 0;
  background-color: var(--light-theme);
}

tr.row-selected td.colSelected { 
  background: white;
  position: relative;
  width: 110px;
  padding: 0;
}

tr.row-selected td.colSelected button {
  background: white;
  color: var(--theme);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}


.history_table .p-datatable-wrapper { 
  overflow: visible !important;
}

tr.selectedRow, .selectedId {
  background: #444444;
  color: #fafafa;
}

.MuiAccordionDetails-root {
  padding: 0 1rem;
}