.wrapper--law .bg--banner{
    height: 295px;
    background-image: url('@/assets/images/top/banner--img-law.svg');
}
.wrapper--law .title_h1--banner{
    font-size: 40px;
    font-weight: 400;
}

@media (min-width: 1200px){
    .container__1154{
        max-width: 1154px;
    }
}


.main--law{
    padding: 60px 0;
}
.title_head_law{
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    color: #000000;
    margin-bottom: 40px;
}
.item_Table{
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: "Oswald", sans-serif;
    color: #000000;
}
.itemTD{
    max-width: 50%;
    flex: 0 0 50%;
    border: 1px solid #C6C6C6;
    border-bottom: 0;
    padding: 27px 30px;
    line-height: 1.7;
    align-content: center;
}
.itemTD:first-child{
    background-color: #F8F8F8;
    border-right: 0;
    font-weight: bold;
}
.item_Table:last-child .itemTD{
    border-bottom: 1px solid #C6C6C6;
}

@media (max-width: 575px){
    .itemTD{
        padding: 15px 13px;
        font-size: 14px;
    }
    .itemTD:first-child{
        max-width: 30%;
        flex: 0 0 30%;
    }
    .itemTD:last-child{
        max-width: 70%;
        flex: 0 0 70%;
    }
}

