.container {
  padding: 0px;
}
.benchmarkHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid #c1c1c1;
}
.action {
  margin: 0px 5px;
}
.action p {
  margin: 0;
}
.boxAction {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  border: 1px solid #c1c1c1;
  border-radius: 36px;
  width: 6rem;
  font-size: 1rem;
}
.boxAction:hover {
  cursor: pointer;
}
.boxAction.view {
  border-color: #27ae60;
  color: #27ae60;
}
.boxAction.copy {
  border-color: #3498db;
  color: #3498db;
}
.boxAction.delete {
  border-color: #e74c3c;
  color: #e74c3c;
}
.boxAction.view svg {
  transform: rotate(180deg);
}
.delete svg path {
  stroke: #e74c3c;
}
.delete:hover svg path {
  stroke: var(--color-white);
}
.benchmarkTable {
  margin-top: 15px;
  word-break: break-all;
}
.benchmarkTable tr.row:not(.noHover):hover {
  border-color: transparent;
  cursor: unset;
}
.row:hover .boxAction {
  border-color: var(--color-white);
  color: var(--color-white);
}
.row:hover .delete svg path {
  stroke: var(--color-white);
}
tbody {
  border-radius: 15px;
}
tr.row {
  height: 76px;
  border-radius: 15px;
  margin-top: 5px;
}
tr.row th,
tr.row td {
  padding: 13px;
}
/* tr.row td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
tr.row td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
} */
.benchmarkTable thead tr span {
  padding: 0px 13px;
}
.title {
  padding: 20px 13px;
  font-weight: 600;
}
button.btnAddBenchmark {
  width: 12.5rem;
  height: 3.125rem;
  border-radius: 36px;
  line-height: normal;
}
/* Add New */
.form {
  font-family: 'Inter', sans-serif;
  flex: 1;
  margin-top: 32px;
}
.inputGroup,
.rowForm {
  display: flex;
  justify-content: space-between;
}
.rowForm {
  width: 100%;
  margin-bottom: 15px;
}
.rowForm label.label {
  font-weight: 600;
  color: #414141;
}
.rowForm:not(.inline) label.label {
  flex: 1;
}
.rowForm:not(.notification):not(.theme) label.label {
  margin-top: 20px;
}
.inputGroup {
  flex: 1;
  margin-right: 30px;
}
.inputGroup input {
  padding: 0;
  padding-left: 30px;
}
.inpContainer {
  align-items: end;
  flex: 2;
}
.inpContainer > div {
  width: 100%;
}
div.addMore {
  cursor: pointer;
  padding: 10px;
}
.iconAddMore svg path {
  stroke: var(--theme);
}
.icon {
  cursor: pointer;
  color: var(--light-theme);
}

.wrapper {
  position: relative;
  height: 100vh;
  overflow: auto;
}
.wrapper .wrapperContent {
  position: relative;
  height: 100vh;
  overflow: auto;
}
.wrapper .wrapperContent.scaleHorizontal {
  position: relative;
  width: calc(100% - 25vw);
  height: 100vh;
  overflow: auto;
}
.benchmarkContainer {
  padding: 0px 4.25rem;
  margin: 3.44rem 0px;
}
.titleContainer {
  display: flex;
  align-items: center;
  min-height: 59px;
  margin-bottom: 18px;
}
.titleContainer svg {
  margin-left: 20px;
  margin-right: 20px;
}
.titleContent {
  font-weight: 700;
  margin-bottom: 0;
  word-break: break-all;
}
.dateRangeContainer {
  margin: 15px 0px;
}
.dateRangeContainer label:first-of-type {
  margin-right: 15px;
}
.colTitle {
  max-width: 300px;
  position: relative;
}
.colTitle .rowContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.colTitle .rowContainer .colTitleContent{
  height: 100%;
  justify-content: center;
} 
.keyword {
  border: 1px solid var(--theme);
  border-radius: 15px;
  padding: 3px 10px;
  margin: 0px 5px;
  color: var(--theme);
}
.siteList,
.keywordList {
  display: flex;
  flex-wrap: wrap;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid var(--theme);
  border-radius: 15px;
}
div.colKeywords {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}
div.colKeywords span {
  margin-bottom: 5px;
}
tr.colSelected {
  padding: 0;
}
tr.colSelected button {
  right: 8px;
  border-radius: 8px;
  height: 100%;
  align-self: center;
  padding: 0 1rem;
}
tr.colSelected button.selected {
  color: var(--theme);
  background: white;
}
button.backButton {
  display: flex;
  line-height: 1.56rem;
  margin: 10px;
}
button.backButton span {
  line-height: normal;
  margin-top: 1px;
}
.colActions {
  width: 360px;
}
.colDates {
  width: 300px;
}
.colDate {
  width: 120px;
}
.colTitle {
  width: 250px;
  position: relative;
}
.colKeywords {
  width: max-content;
  max-width: 150px;
}
.radios label {
  width: 40%;
}
.theme .radios label {
  width: 30%;
}
.radios {
  margin-top: 20px;
}
.boxContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-bottom: 15px;
}
.bottomButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.bottomButtons button {
  margin: 0px 5px;
}
.bottomButtons button.stopButton {
  width: 10rem;
}
.stackStop {
  display: flex;
  align-items: center;
}

.stackStop > span {
  margin-bottom: 20px;
}
.stackStopResponsing {
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;
  padding: 15px 0px;
}
.stackStopResponsing button {
  background-color: var(--theme);
  color: var(--color-white);
}
.stackStopResponsing button:hover {
  background-color: var(--light-theme);
}
.bottomButtons button.btnAddBenchmark > span > svg {
  font-size: 30px;
}
@media (max-width: 1400px) {
  .action p {
    display: none;
  }
  .boxAction {
    width: 3.5rem;
    height: 30px;
  }
  .action {
    margin: 0px 3px;
  }
  .colDates {
    width: 220px;
  }
  .colTitle {
    width: 180px;
    position: relative;
  }
  .colKeywords {
    width: 120px;
  }
}
@media (max-width: 1100px) {
  button.backButton {
    margin: 10px 60px;
  }
}
@media (max-width: 900px) {
  .colDates {
    width: 115px;
  }
}
@media (max-width: 780px) {
  .inline {
    flex-direction: column;
  }
  .inputGroup .inpContainer {
    width: 30rem;
  }
  .row label.label {
    font-size: 1.3rem;
  }
  .notification .radios label {
    width: auto;
    margin-right: 3rem;
  }
  .notification .radios label:last-child {
    width: 50%;
  }
  .notification > label {
    width: 210px;
  }
  .boxAction {
    width: 6rem;
  }
  ul.actions {
    flex-direction: column;
  }
  .action {
    margin: 3px 0px;
  }
  .colDate {
    min-width: 100px;
  }
  .colSummary {
    min-width: 400px;
  }
  button.btnAddBenchmark {
    width: 15.5rem;
  }
}
@media (max-width: 570px) {
  .radios {
    flex-direction: column;
  }
  .radios label {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .benchmarkContainer {
    padding: 0px 2rem;
  }
}

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 360px;
  min-width: fit-content;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 12px 16px;
  cursor: pointer;
  list-style-type: none;
  border-radius: 4px;
  top: 120%;
  white-space: nowrap;
}
.dropdownMenuShifted {
  right: -125px;
}
.dropdownMenuAligned {
  right: 0px;
}

.dropdownMenu li {
  color: black;
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.dropdownMenu li:hover {
  background-color: #f1f1f1;
  width: 100%;
}
.dropdownMenu li img {
  padding-right: 4px;
}
.dropdownMenu li p {
  margin: 0;
} 
.scaleHorizontal {
  transform-origin: left;
  transition: transform 0.3s ease;
}