@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.bg--banner{
    height: 230px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('@/assets/images/top/banner--img-policy.svg');
    padding-top: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title_h1--banner{
    font-family: "Oswald", sans-serif;
    font-size: 64px;
    font-weight: 400;
    margin:  0 auto;
    color: #fff;
}
.container{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
.img-fluid{
    max-width: 100%;
    height: auto;
}
@media (min-width: 1260px){
    .container__1230{
        max-width: 1230px;
    }
}
.main--policy{
    padding: 54px 0 50px;
    color: #000000;
    line-height: 25px;
    font-family: "Oswald", sans-serif;
}
.main--policy .content--txt{
    margin-bottom: 50px;
}
.title__h4{
    margin: 0;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 9px;
    margin-bottom: 25px;
    border-bottom: 1px solid #BFBFBF;
}
.list__stt{
    padding-left: 20px;
    list-style-type: auto;
}
.list__stt li{
    padding-left: 5px;
}
.list__stt li:not(:last-child){
    margin-bottom: 4px;
}
.list__number{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.list__number li{
    padding-left: 20px;
    position: relative;
}
.list__number li span{
    position: absolute;
    left: 6px;
    top: 2px;
}

.policy__contact{
    display: flex;
    align-items: center;
    padding: 30px 50px 26px;
    border: 1px solid #000000;
    font-family: "Oswald", sans-serif;
    -ms-flex-direction: row-reverse ;
    flex-direction: row-reverse ;
}
.policy__contact .cLeft{
    flex: 0 auto;
    width: 60%;
    color: #000000;
}
.policy__contact .cRight{
    flex: 0 auto;
    width: 40%;
    text-align: right;
    padding-left: 50px;
}
.policy__contact .cRight .img-fluid{
    width: 270px;
}
.policy__contact .cLeft .cHead{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 23px;
}
.policy__contact .cLeft .cList__txt{
    list-style-type: none;
    font-size: 16px;
}
.policy__contact .cLeft .cList__txt li:not(:last-child){
    margin-bottom: 20px;
}


@media (max-width: 575px){
    .policy__contact{
        flex-wrap: wrap;
        padding: 22px 15px 16px;
    }
    .policy__contact .cRight,
    .policy__contact .cLeft{
        flex: 0 auto;
        width: 100%;
    }
    .policy__contact .cRight{
        text-align: center;
        margin-bottom: 25px;
        padding: 0;
    }
    .policy__contact .cRight .img-fluid{
        width: auto;
    }
    .policy__contact .cLeft .cHead{
        font-size: 20px;
    }
}





