.formContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .formContainer {
    margin: 15px;
  }
}
.buttonSubmit {
  text-align: center;
  padding-bottom: 100px;
}
.buttonSubmit button {
  height: 3.125rem;
  border-radius: 55px;
  width: 15rem;
  font-weight: 700;
  margin: 0px 10px;
  line-height: normal;
}
.formTitle {
  font-weight: 600;
  font-size: 2rem;
}
button.cancelButton,
button.cancelButton:hover {
  background-color: #7a7a7a;
}
.appLayout {
  position: relative;
  margin-left: 250px;
  min-height: 100vh;
  background-color: #fafafa;
}
@media (max-width: 1100px) {
  .appLayout {
    margin: 0px;
    z-index: 0;
  }
}
