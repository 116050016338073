.loginContainer {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
}
.imgRespon {
  max-width: 100%;
  height: auto;
}
.bgFormLogin {
  flex: 0 0 54.35%;
  max-width: 54.35%;
  position: relative;
}
.bgFormLogin::before {
  position: absolute;
  content: '';
  right: -1px;
  top: 58.5%;
  transform: translateY(-50%);
  width: 2px;
  height: 388px;
  background-color: var(--light-theme);
}
.formLogin {
  margin: auto;
  width: 430px;
  padding-top: 7.625em;
}
.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
button.btnSubmit {
  width: 100%;
  color: #fff;
  border-radius: 47px;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 90px;
  border-style: none;
  margin-top: 61px;
  height: 50px;
}
button.btnSubmit:hover {
  box-shadow: none;
}
.title {
  font-size: 2.25rem;
  font-family: 'Oswald', sans-serif;
  margin-bottom: 45px;
  letter-spacing: 0.05em;
}
.forgotPassword {
  font-family: 'Inter', sans-serif;
  display: block;
  text-decoration: none;
  margin-top: 25px;
  font-weight: 700;
  font-size: 0.875rem;
}
.label {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #535353;
  margin-bottom: 0;
}
input.input {
  height: 65px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  padding: 0 0 0 10px;
}
@media (max-width: 980px) {
  .bgFormLogin {
    display: none;
  }
  .formLogin {
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .formLogin{
    width: 100%;
    max-width: calc(100% - 40px);
  }
}
