.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: var(--light-theme);
  color: var(--color-white);
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: var(--light-theme);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #414141;
  width: 2rem;
  line-height: 2rem;
  margin: 0.5rem;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #ccc;
}

.react-datepicker {
  padding: 15px;
}

.react-datepicker__header {
  background-color: transparent;
}

.react-datepicker__close-icon {
  top: 20px;
  height: auto;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  cursor: pointer;
  color: #414141;
  height: 24px;
  width: 24px;
  font-size: 24px;
  content: '×';
}

.react-datepicker__header .custom-header,
.react-datepicker__header .custom-header .custom-header-dropdown {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.react-datepicker__header .custom-header button {
  background-color: transparent;
}
.react-datepicker__header .custom-header .custom-header-dropdown select {
  color: #000;
  font-size: 0.944rem;
  font-weight: 700;
  margin-top: 0;
}
.react-datepicker__header .custom-header .custom-header-dropdown select,
.react-datepicker__header .custom-header .custom-header-dropdown select:focus-visible {
  border: none;
  outline: none;
}
