.form {
  font-family: 'Inter', sans-serif;
  flex: 1;
  width: 45.3125rem;
  margin-top: 32px;
}
.inputGroup,
.row {
  display: flex;
  justify-content: space-between;
}
.row {
  width: 100%;
  margin-bottom: 15px;
}
.row label.label {
  font-weight: 600;
  color: #414141;
}
.row:not(.inline) label.label {
  flex: 1;
}
/* .row:not(.inline) > div > div {
  flex: 2;
} */
/* .row.inline label.label {
  margin-right: 20px;
} */
.row:not(.notification):not(.theme) label.label {
  margin-top: 20px;
}
.inputGroup {
  flex: 1;
  margin-right: 30px;
}
.inputGroup {
  margin-right: 30px;
}
.inputGroup input {
  padding: 0;
  padding-left: 30px;
}
.inpContainer {
  width: 296px;
}
.notification,
.theme {
  margin-bottom: 35px;
  align-items: center;
}
.notification .radios label {
  width: 40%;
}
.theme .radios label {
  width: 30%;
}
.form .notification label.label,
.form .theme label.label {
  flex: none;
}
.notification > label {
  width: 240px;
}
.theme > label {
  width: 112px;
  margin-right: 40px;
}
.strengPasswordBar {
  margin-top: 13px;
  display: flex;
  flex-direction: row-reverse;
}
.strengPasswordBar > div {
  flex: 2;
  margin-left: 14px !important;
}
.strengPasswordBar div div {
  height: 10px !important;
  border: 1px solid #cecece;
}
.strengPasswordBar div div:first-child {
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
}
.strengPasswordBar div div:last-child {
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
}
.strengPasswordBar div div:nth-child(2n) {
  width: 0 !important;
  border: 0px;
}
.strengPasswordBar div div:nth-child(n + 2) {
  border-left: 0px;
}
@media (max-width: 1100px) {
  .form {
    width: 46.154rem;
  }
}
@media (max-width: 780px) {
  .inline {
    flex-direction: column;
  }
  .inputGroup .inpContainer {
    width: 30rem;
  }
  .row label.label {
    font-size: 1.3rem;
  }
  .notification .radios label {
    width: auto;
    margin-right: 3rem;
  }
  .notification .radios label:last-child {
    width: 50%;
  }
  .notification > label {
    width: 210px;
  }
}
