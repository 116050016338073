footer .container {
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 40px;
  font-family: "Oswald", sans-serif;
}
.footer {
  border-top: 2px solid #dedede;
  background-color: #fafafa;
}
.footerLogo {
  border-bottom: 1px solid #cacaca;
  margin-bottom: 30px;
  padding-top: 65px;
  padding-bottom: 25px;
}
.footerLogo img {
  width: auto;
}
.footerNav {
  display: flex;
}
.footerNav > div {
  flex: 25%;
}
.footerNav ul {
  padding: 0;
  margin: 0;
}
.footerNav ul li {
  list-style: none;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;
}
.footerNav ul li a {
  color: #434343;
  text-decoration: none;
  font-weight: 400;
}
.copyright {
  font-size: 0.9375rem;
  font-weight: 700;
  color: #004EA2;
  margin-bottom: 0;
}
.footerNav > div:first-child {
  padding-left: 5px;
}
.imgExternalLink {
  margin-left: 5px;
}
.footerNav > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

@media (max-width: 780px) {
  footer .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  footer img.logoFinch {
    width: 100px;
  }
}
@media (max-width: 575px) {
  .footer{
    border-width: 1px;
  }
  footer .container {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 15px;
  }
  .footerNav {
    flex-wrap: wrap;
  }
  .footerLogo{
    padding-top: 19px;
    padding-bottom: 18px;
    text-align: center;
    margin-bottom: 10px;
  }
  .footerNav > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .footerNav div:nth-last-child(3) ul li{
    padding: 10px 0;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
  }
  .footerNav div:nth-last-child(2) ul li:last-child{
    border-bottom: 1px solid #cacaca;
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
  .footerNav > div:first-child{
    padding-left: 0;
  }
  .footerNav ul li{
    height: auto;
    line-height: initial;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
  }
  .footerNav ul li a{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 100%;
  }
  footer img.logoFinch {
    width: 150px;
    margin: 0 auto 27px;
  }
  .copyright{
    font-size: 12px;
    width: 100%;
    text-align: center;
    font-weight: 400;
  }
}
