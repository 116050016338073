.radioButtons {
  flex: 2;
  display: flex;
  justify-content: center;
}

@media (max-width: 780px) {
  .radioButtons span {
    font-size: 1.3rem;
  }
}
