.boxResponsed {
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}

.boxResponsed.questionSelected {
  background-color: var(--light-theme);
  border-radius: 10px;
}

.boxResponsed.selectedId {
  background-color: #44444498;
  color: #fafafa;
  border-radius: 10px;
}

.boxResponsed:last-child {
  border-bottom: 0px;
}

.searchPage {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.searchPage .searchPageContent {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.searchPage .searchPageContent.scaleHorizontal {
  position: relative;
  width: calc(100% - 25vw);
  height: 100vh;
  overflow: auto;
}
.containerSearch {
  margin: 0;
  align-items: flex-end;
  margin-top: 20px;
}
.containerSearch .inputContainer input {
  height: 43px;
  padding-left: 6px;
  font-weight: 500;
}
button.buttonSearch {
  min-width: 65px;
  width: 65px;
  height: 65px;
}
.boxSearchBar {
  position: sticky;
  bottom: 25px;
  border-top: 1px solid #dedede;
  background-color: #fcfcfc;
  margin-top: 15px;
}
.boxTitle {
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #414141;
  margin-bottom: 10px;
  margin-top: 10px;
}
.boxTitle img {
  padding: 0px 15px;
}
.boxContent,
.boxContent > div,
.boxContent p {
  white-space: pre-wrap;
  line-height: 26px;
}
.boxContent,
.boxContent > div {
  margin: 0 7px;
  text-align: justify;
}
.boxSources .boxContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 7.5px 0px;
  flex-wrap: wrap;
  flex-basis: 20%;
}
.boxContent .cardSource {
  border: 1px solid #dedede;
  box-shadow: none;
  border-radius: 15px;
  padding: 0px;
  margin: 0px 10px;
}
.cardSource .cardContent {
  padding: 20px 10px 10px 10px;
  border-bottom: 1px solid #dedede;
  height: 80px;

  &.isSelected {
    color: #414141;
    background: var(--light-theme);
  }
}
.cardSource .cardContent p {
  margin: 0;
  font-size: 0.8125rem;
}
.sourceLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.isSelected {
  color: #414141;
  background: var(--light-theme);
}
.sourceLink p {
  flex: 2;
  padding-left: 10px;
  font-size: 0.875rem;
  text-decoration: none;
  margin: 0;
}
.sourceLink img {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.sourceIndex {
  background-color: var(--light-theme);
  display: inline-block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.sourceIndex:hover {
  background-color: var(--theme);
}
.readMore {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
}
.cardSource .emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 17px 50px;
}
.emptyContent img {
  width: 35px;
  margin: 2.5px;
}
.cardSourceReadMore {
  cursor: pointer;
}

.answerWrapper {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 45px;
}
.truncateOverflow {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 1rem;
}
.linkCard {
  text-decoration: none;
  transition: 0.3s ease all;
  opacity: 0;
}
.answerWrapper .boxQuestion {
  border-bottom: 1px solid #dedede;
  padding-top: 15px;
}
.answerLink a {
  background-color: var(--light-theme);
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  line-height: 25px;
}
.answerLink a:hover {
  background-color: var(--theme);
}
.content {
  font-weight: 700;
  margin-bottom: 0;
}
.header,
.boxCreatedBy {
  display: flex;
  align-items: center;
}
.header {
  min-height: 59px;
  margin-bottom: 18px;
}
.header svg,
.boxCreatedBy svg {
  margin-left: 20px;
  margin-right: 20px;
}
.boxCreatedBy {
  min-height: 49px;
  color: #414141;
  border-bottom: 1px solid #cbcbcb;
}
.boxCreatedAndSelect {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cbcbcb;
}
.boxCreatedAndSelect button {
  right: 8px;
  border-radius: 8px;
  height: 33px;
  align-self: center;
  padding: 0 1rem;
  color: white;
  background-color: var(--theme);
}
.boxCreatedAndSelect button.selected {
  color: var(--theme);
  background: white;
}
.boxCreatedAndSelect button:hover {
  color: white;
  background-color: var(--theme);
}
button.backButton {
  display: flex;
  line-height: 1.56rem;
  margin: 10px;
}
button.backButton span {
  line-height: normal;
  margin-top: 1px;
}
@media (max-width: 1100px) {
  .boxTitle,
  .cardSource .cardContent p {
    font-size: 1.077rem;
  }
  .cardSource .cardContent {
    height: 85px;
    padding: 5px 10px;
  }
  button.backButton {
    margin: 10px 60px;
  }
}
@media (max-width: 780px) {
  .answerWrapper {
    margin: 60px 15px 20px 15px;
  }
  .content,
  .boxTitle,
  .cardSource .cardContent p {
    font-size: 1.3rem;
  }
  .boxContent,
  .boxContent > div {
    font-size: 1.3rem;
  }
  .boxContent .cardSource {
    margin: 5px;
  }
}
@media (max-width: 600px) {
  .answerWrapper {
    margin: 60px 15px 20px 15px;
  }
  .content,
  .boxTitle,
  .cardSource .cardContent p {
    font-size: 1.3rem;
  }
  .boxContent,
  .boxContent > div {
    font-size: 1.3rem;
  }
  .boxSources .boxContent {
    justify-content: center;
  }
}

.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 360px;
  min-width: fit-content;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 12px 16px;
  cursor: pointer;
  list-style-type: none;
  border-radius: 4px;
  top: 120%;
  white-space: nowrap;
}
.dropdownMenuShifted {
  right: -125px;
}
.dropdownMenuAligned {
  right: 0px;
}

.dropdownMenu li {
  color: black;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.dropdownMenu li img {
  padding-right: 4px;
}

.dropdownMenu li p {
  margin: 0;
}
.dropdownMenu li:hover {
  background-color: #f1f1f1;
  width: 100%;
}

.color-theme {
  font-size: 24px;
  color: var(--theme);
  font-weight: bold;
}
.drawer-content {
  padding: 85px 20px 35px 20px; 
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative
}
.drawer-filename, .drawer-listing {
  padding-top: 15px;
  border-bottom: 1px solid #cbcbcb;
}
.drawer-listing {
  flex-grow: 1;
  overflow-y: auto
}
.drawer-sub-title {
  font-family: Oswald;
  font-size: 15px;
  font-weight: 700;
  line-height: 22.23px;
  text-align: left;
}
p.fileName {
  padding-left: 0px !important;
  height: 40px;
}
.questionItem {
  border-bottom: 1px solid #cbcbcb;
  height: 40px;
  padding-left: 0px !important;
}
.drawerButton {
  height: 55px;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 15px !important;
}

.scaleHorizontal {
  transform-origin: left;
  transition: transform 0.3s ease;
}