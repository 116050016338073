.p-datatable {
  display: flex;
  flex-direction: column-reverse;
}
.p-paginator {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.p-paginator button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: transparent;
}

.p-paginator button:hover {
  background-color: var(--light-theme);
  color: #fff;
}

.p-paginator button.p-highlight {
  background-color: var(--theme);
  color: #fff;
}

.p-datatable-table .row-table {
  min-height: 76px;
  border-bottom: 1px solid #dedede;
  margin-top: 5px;
  padding: 13px;
}

.p-datatable-table .row-table.space-between {
  justify-content: space-between;
}

.p-datatable-table .row-table:not(.no-hover):hover {
  color: var(--color-white);
  cursor: pointer;
  border-radius: 15px;
}

.p-datatable-table .row-table .actions ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
}

.p-datatable-table .p-datatable-emptymessage {
  text-align: center;
  height: 48px;
}
.p-hidden-accessible {
  border: 0;
  padding: 0;
  margin: -1px;
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}
.p-dropdown {
  background: #fafafa;
  border: 1px solid #d1d5db;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
  padding: 5px 10px;
}
.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6b7280;
  width: 2rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.p-dropdown-panel {
  background: #fafafa;
  color: #4b5563;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #4b5563;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--color-white);
  background: var(--light-theme);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  margin-top: 0;
}
