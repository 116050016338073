.loginContainer {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
}
.formLogin {
  margin: auto;
}
.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
button.btnSubmit {
  width: 26.75rem;
  color: #fff;
  border-radius: 47px;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 90px;
  border-style: none;
  margin-top: 35px;
  height: 65px;
}
button.btnSubmit:hover {
  box-shadow: none;
}
.title {
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 35px;
  font-weight: 700;
}
.forgotPassword {
  font-family: 'Inter', sans-serif;
  display: block;
  text-decoration: none;
  margin-top: 25px;
  font-weight: 700;
  font-size: 0.875rem;
}
.label {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #535353;
  margin-bottom: 0;
}
input.input {
  height: 65px;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.responseWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 710px; */
  margin: 200px auto;
  text-align: center;
}
.boxIcon {
  width: 9.375rem;
  height: 9.375rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxText {
  margin-top: 2.8125rem;
  margin-bottom: 4.25rem;
}
.boxText label {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 45px;
}
.boxText p {
  font-size: 1.25rem;
}
.boxImageLeft {
  position: relative;
  overflow: hidden;
  max-width: 785px;
  max-height: 978px;
}

.imageBg {
  position: relative;
  top: -165px;
  left: -193px;
  animation-name: rollBg;
  animation-duration: 0.3s;
  transform-origin: 0px 320px;
}
.lblSuccess {
  padding: 150px 0px;
  text-align: center;
  color: #535353;
}
@keyframes rollBg {
  0% {
    rotate: -15deg;
  }
  100% {
    rotate: 0deg;
  }
}
.imageBox {
  position: absolute;
  left: 56%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation-name: rollImage;
  animation-duration: 0.55s;
}
@keyframes rollImage {
  0% {
    left: -20%;
  }
  90% {
    left: 57.5%;
  }
  100% {
    left: 56%;
  }
}
@media (max-width: 980px) {
  .boxImageLeft {
    display: none;
  }
  .formLogin {
    margin: 30px auto;
  }
  .lblSuccess {
    padding: 75px 45px;
    line-height: 30px;
  }
}
