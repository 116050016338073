.container {
    width: 80%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto 75px;
}
.managerHeader {
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin-top: 80px;
    margin-bottom: 10px;
}
.managerHeader label {
    flex: 1;
    font-size: 2rem;
    font-weight: 600;
}
.managerTitle {
    font-family: "Oswald", sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.color-theme {
    font-size: 24px;
    color: var(--theme);
    font-weight: bold;
}

.pieceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pieceHeader h6 {
    margin: 0;
}

.pieceSettingContain {
    margin: 10px 0px;
}

.pieceSettingTitleItem {
    color: var(--theme);
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    border-bottom: 1px solid var(--theme);
}

.pieceSettingItem {
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: baseline
}

.pieceSettingItem .itemTitle {
    font-size: 16px;
    font-weight: 400;
}

.pieceSettingItem .sendMailBtn {
    width: max-content;
    padding: 10px 20px;
    background-color: var(--theme)
}
.pieceSettingItem .sendMailBtn:hover {
    width: max-content;
    padding: 10px 20px;
    background-color: var(--light-theme)
}

.addMore {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.iconAddMore {
    width: 20px;
    height: 20px;
}

.siteList,
.keywordList {
    display: flex;
    margin-top: 10px;
    flex-direction: row
}
.keyword {
    border: 1px solid var(--theme);
    border-radius: 15px;
    padding: 3px 10px;
    margin: 0px 5px;
    color: var(--theme);
}
.siteList,
.keywordList {
    display: flex;
    flex-wrap: wrap;
}
.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid var(--theme);
    border-radius: 15px;
    width: fit-content;

}

.item p {
    margin: 0;
    font-size: 14px;
}

.icon {
    cursor: pointer;
    color: #ff6b6b;
    margin-left: 10px;
}

.d-none {
    display: none;
}

.uploadSection {
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 4px;
    border: 1px dashed #ccc;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
}

.uploadSection:hover {
    background-color: #e9e9e9;
}

.error {
    color: red;
    font-size: 12px;
}

.iconAddMore svg path {
    stroke: var(--theme);
}
.icon {
    cursor: pointer;
    color: var(--light-theme);
}
div.addMore {
    cursor: pointer;
    padding: 10px;
}
.notFoundText {
    margin: 15px 0px;
    text-align: center;
}