.inpContainer {
  /* align-items: end; */
  flex: 5;
}
.inpContainer > div {
  width: 100%;
}
.inputGroup input {
  padding: 0;
  padding-left: 30px;
}
.inputGroup,
.rowForm {
  display: flex;
  justify-content: space-between;
}
.rowForm {
  width: 100%;
  margin-bottom: 15px;
}
.rowForm label.label {
  font-weight: 600;
  color: #414141;
}
.rowForm:not(.inline) label.label {
  flex: 1;
}
.rowForm:not(.notification):not(.theme) label.label {
  margin-top: 20px;
}
.inputGroup {
  flex: 1;
  margin-right: 30px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid var(--light-theme);
  border-radius: 15px;
}
.icon {
  cursor: pointer;
  color: var(--light-theme);
}
.strengPasswordBar {
  margin-top: 13px;
  display: flex;
  flex-direction: row-reverse;
}
.strengPasswordBar > div {
  flex: 2;
  margin-left: 14px !important;
}
.strengPasswordBar div div {
  height: 10px !important;
  border: 1px solid #cecece;
}
.strengPasswordBar div div:first-child {
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
}
.strengPasswordBar div div:last-child {
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
}
.strengPasswordBar div div:nth-child(2n) {
  width: 0 !important;
  border: 0px;
}
.strengPasswordBar div div:nth-child(n + 2) {
  border-left: 0px;
}
@media (max-width: 780px) {
  .inputGroup .inpContainer {
    width: 30rem;
  }
}
