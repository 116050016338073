.form {
  flex: 1;
  max-width: 713px;
  margin-top: 32px;
}
.inputGroup,
.row {
  display: flex;
  align-items: center;
}
.row {
  width: 100%;
  margin-bottom: 35px;
}
.inputGroup {
  flex: 1;
  justify-content: flex-start;
}
.inputGroup {
  margin-right: 30px;
}
.inputGroup input {
  padding: 0;
  flex: 2;
}
.label {
  flex: 1;
  font-weight: 600;
  color: #414141;
}
.inpContainer {
  flex: 2;
}
.passwordInp {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.strengPasswordBar {
  margin-top: 13px;
  display: flex;
  flex-direction: row-reverse;
}
.strengPasswordBar > div {
  flex: 2;
  margin-left: 14px !important;
}
.strengPasswordBar div div {
  height: 10px !important;
  border: 1px solid #cecece;
}
.strengPasswordBar div div:first-child {
  border-bottom-left-radius: 17px;
  border-top-left-radius: 17px;
}
.strengPasswordBar div div:last-child {
  border-bottom-right-radius: 17px;
  border-top-right-radius: 17px;
}
.strengPasswordBar div div:nth-child(2n) {
  width: 0 !important;
  border: 0px;
}
.strengPasswordBar div div:nth-child(n + 2) {
  border-left: 0px;
}
