@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.theme .background-image-theme {
  background-image: var(--bg-login-theme);
}
.theme .background-theme,
.theme .background-theme-hover:hover {
  background-color: var(--theme);
  border-color: var(--theme);
}
.theme .color-theme {
  color: var(--theme);
}
.theme .edit-disable {
  color: rgba(0, 0, 0, 0.26);
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(224, 224, 224, 0);
  border-radius: 36px;
}
.bg-color-theme {
  color: white;
  background-color: var(--theme);
}
.theme .MuiInput-root:hover:not(.Mui-disabled, .Mui-error, .not-apply-theme)::before {
  border-bottom: 1px solid var(--theme);
}
.theme .MuiInput-root::after {
  border-bottom: 2px solid var(--theme);
}
.theme a.dropdown-item.active,
.theme a.dropdown-item:active,
.theme a.dropdown-item:hover {
  background-color: var(--theme);
  color: var(--color-white);
}
.theme .bg-theme-hover-opacity .MuiBox-root:hover {
  background-color: var(--light-theme);
}
.theme .search-icon::before {
  background-image: var(--search-icon-theme);
}
.theme .border-bottom-theme,
.theme .border-bottom-theme:hover {
  border: 1px solid var(--theme);
}
.theme .border-bottom-theme-2 {
  border-bottom: 2px solid var(--theme);
}
.theme .border-right-theme-3:not(:last-child)::before {
  background-color: var(--theme);
}
.theme .MuiOutlinedInput-root:hover:not(.Mui-disabled, .Mui-error, .not-apply-theme) fieldset,
.theme .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.theme .ouline {
  border-color: var(--theme);
}
.theme .background-image-theme-1 {
  background-image: var(--bg-unauthen-theme);
}
@media (max-width: 980px) {
  .theme .background-image-theme,
  .theme .background-image-theme-1 {
    background-image: none;
  }
}
.scrollEC img {
  max-height: 100%;
  height: auto;
}
.scroll--contact,
.scroll--what,
.scroll--news-detail {
  list-style-type: none;
}
