.formContainer {
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 150px 0px 100px 0px;
}
.formTitle {
  margin-bottom: 28px;
}
.inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.label,
.memberTable tr td {
  color: #414141;
}
.inputGroup input {
  padding: 0;
}
.inpContainer {
  flex: 2;
  margin-left: 60px;
  margin-right: 40px;
}
.inputGroup button {
  border-radius: 40px;
  height: 50px;
  width: 125px;
}

div.tableContainer,
.formTitle,
.inviteMember,
table.memberTable,
.skeleton {
  width: 100%;
  margin: auto;
}
.inviteMember {
  flex: 1;
}
.memberTable {
  border: none;
}
div.tableContainer {
  border-bottom: 1px solid #535353;
  margin-bottom: 80px;
  overflow-x: hidden;
}
.memberTable tr td {
  border-bottom: 1px solid #cecece;
  position: relative;
}
.memberTable tr td:first-child::before {
  position: absolute;
  content: '';
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 15px;
  background-color: #dedede;
  border-radius: 16px;
}
.memberTable tr td:last-child {
  border-bottom: 0px;
}
.memberTable tr td.banned {
  color: #e14646;
}
.label,
.memberTable tr td:first-child,
.memberTable tr td.status,
.labelSwicth span:last-child {
  font-weight: 600;
}
.labelSwicth span:last-child {
  font-size: 0.875rem;
}
.inputGroup button,
.label {
  margin-top: -20px;
}
button.btnRemove {
  height: 40px;
  color: #e14646;
  line-height: 21px;
  border-radius: 40px;
}
.btnRemove svg path {
  stroke: #e14646;
}
@media (max-width: 1400px) {
  div.tableContainer {
    width: 90%;
    margin: auto;
    margin-bottom: 80px;
    overflow-x: auto;
  }
  .skeleton {
    margin: 15px auto 45px auto;
  }
  table.memberTable {
    width: 1053px;
  }
  .inviteMember {
    width: 100%;
  }
  .formTitle {
    margin: auto;
    width: 100%;
  }
  .label {
    font-size: 1rem;
  }
  .inpContainer {
    margin: 0 25px;
  }
}
@media (max-width: 1100px) {
  .memberTable tr td,
  .memberTable tr th {
    font-size: 1rem;
  }
}
@media (max-width: 780px) {
  .memberTable tr th,
  .memberTable tr td {
    max-width: 100px;
    font-size: 1.3rem;
  }
  .formTitle {
    margin: auto;
  }
  .label {
    font-size: 1.3rem;
  }
  .inpContainer {
    margin: 0 25px;
  }
  .inviteMember {
    width: 100%;
  }
  .inputGroup {
    width: 85%;
    margin: auto;
  }
}
