.container {
  padding: 0px;
}
.historiesContainer {
  padding: 30px 68px;
}
.historyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid #c1c1c1;
}
.historyHeader label {
  flex: 1;
  font-size: 1.125rem;
  font-weight: 600;
}
.historyHeader img {
  padding-left: 38px;
  padding-right: 17px;
}
.group {
  padding-left: 38px;
  margin-bottom: 25px;
}
.group label,
.group table span {
  font-size: 1.25rem;
  font-weight: 600;
}
.historiesContainer .group:last-child {
  margin-bottom: 0;
  padding-bottom: 25px;
}
.group [class~='p-rowgroup-header'] {
  position: relative;
}

.group [class~='p-rowgroup-header'] td {
  padding-top: 25px;
  padding-bottom: 10px;
}

.boxSearch {
  display: flex;
  align-items: center;
  width: 21.25rem;
  height: 50px;
}
.boxSearch img {
  padding: 0px 18px;
}
.boxSearch svg {
  margin-left: 1rem;
  margin-right: 1rem;
}
.boxSearch > div:not(.icon) {
  flex: 2;
  height: 100%;
}
.topicRow {
  display: flex;
  align-items: center;
  min-height: 76px;
  border-bottom: 1px solid #dedede;
  margin-top: 5px;
  padding: 13px;
}
.topicRow::before {
  content: '';
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  padding: 0px 25px;
}
.topicRow::before {
  background-image: url('@/assets/icons/search-alt-2.svg');
}
.topicRow:hover {
  color: var(--color-white);
  cursor: pointer;
  border-radius: 15px;
}
.topicRow:hover::before {
  background-image: url('@/assets/icons/search-alt-1.svg');
  background-size: 24px;
  background-repeat: no-repeat;
}

.p-datatable-table .p-rowgroup-header:not(:first-child) {
  margin-top: 25px;
}

.boxSender {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxSender p {
  margin: 0;
  font-weight: 600;
  font-size: 0.875rem;
  color: #414141;
}
.topicRow:hover .boxSender p {
  color: var(--color-white);
}
.boxSender svg {
  margin-right: 15px;
}
.notFoundText {
  margin: 15px 0px;
  text-align: center;
}
.icon svg {
  margin-left: 38px;
  margin-right: 17px;
}
.icon svg path {
  stroke: var(--theme);
}
.boxAction {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  border: 1px solid #c1c1c1;
  border-radius: 36px;
  width: 6rem;
  font-size: 1rem;
}
.boxAction:hover {
  cursor: pointer;
}
.boxAction.view {
  border-color: #27ae60;
  color: #27ae60;
}
.boxAction.copy {
  border-color: #3498db;
  color: #3498db;
}
.boxAction.delete {
  border-color: #e74c3c;
  color: #e74c3c;
}
.boxAction.view svg {
  transform: rotate(180deg);
}
.row:hover .boxAction {
  border: none;
}
.boxAction.view {
  border-color: #27ae60;
  color: #27ae60;
}
.boxAction.copy {
  border-color: #3498db;
  color: #3498db;
}
.boxAction.delete {
  border-color: #e74c3c;
  color: #e74c3c;
}
.boxAction.view svg {
  transform: rotate(180deg);
}
.delete svg path {
  stroke: #e74c3c;
}
.delete:hover svg path {
  stroke: var(--color-white);
}
.action {
  margin: 0px 5px;
}
.action p {
  margin: 0;
}
.topicRow:hover .boxAction {
  border: none;
}
.topicRow:hover .delete svg path {
  stroke: var(--color-white);
}
@media (max-width: 1100px) {
  tbody,
  td,
  th,
  thead,
  tr {
    font-size: 1.077rem;
  }
}
@media (max-width: 780px) {
  tbody,
  td,
  th,
  thead,
  tr {
    font-size: 1.3rem;
  }
  .historiesContainer {
    padding: 0px 15px;
    margin-top: 30px;
  }
  .historyHeader img {
    padding-left: 0px;
    padding-right: 15px;
  }
  .historyHeader label {
    font-size: 1.4rem;
  }
  .group {
    padding-left: 0;
  }
  .boxSender p {
    font-size: 1.2rem;
  }
  .boxAction {
    width: 6rem;
  }
}
.boxActions {
  justify-content: space-between;
  max-width: 350px;
  display: none;
}
.topicRow:hover .boxActions {
  display: flex;
}
.boxActions ul {
  display: flex;
}
.boxActions ul li {
  display: flex;
  align-items: center;
  list-style: none;
  width: 110px;
  text-align: center;
  height: 40px;
  padding: 0px 5px;
  position: relative;
  justify-content: center;
}
.boxActions ul li:not(:last-child)::before {
  position: absolute;
  content: '';
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 22px;
  background-color: #fff;
  border-radius: 16px;
}
.boxActions ul li:last-child {
  border-right: none;
}
.action {
  margin: 0px 5px;
}
.action p {
  margin: 0;
}

.actionCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.shareName {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: opacity 0.3s ease;
}
.row-table:hover .boxActions {
  opacity: 1;
  pointer-events: auto; 
}
.row-table:hover .boxSender {
  opacity: 0;
  pointer-events: none; 
  display: none;
}
.topicRow:hover .boxSender {
  display: none;
}


.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 360px;
  min-width: fit-content;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  padding: 12px 16px;
  cursor: pointer;
  list-style-type: none;
  border-radius: 4px;
  white-space: nowrap;
  top: 120%;
}
.dropdownMenuShifted {
  right: -125px;
}
.dropdownMenuAligned {
  right: 0px;
}
.dropdownMenu li {
  width: 100% !important;
  color: black;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.dropdownMenu li:hover {
  background-color: #f1f1f1;
  width: 100%;
}
.dropdownMenu li img {
  padding-right: 4px;
}
.dropdownMenu li p {
  margin: 0;
} 

.tableWrapper {
  position: relative;
  overflow: visible !important;
}
.tableWrapper > div {
  overflow: visible !important;
}