.loginContainer {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
}
.formLogin {
  margin: auto;
}
.inputGroup {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
button.btnSubmit {
  width: 26.75rem;
  color: #fff;
  border-radius: 47px;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 90px;
  border-style: none;
  margin-top: 35px;
  height: 65px;
}
button.btnSubmit:hover {
  box-shadow: none;
}
.title {
  font-family: 'PT Sans', sans-serif;
  margin-bottom: 35px;
  font-weight: 700;
}
.forgotPassword {
  font-family: 'Inter', sans-serif;
  display: block;
  text-decoration: none;
  margin-top: 25px;
  font-weight: 700;
  font-size: 0.875rem;
}
.label {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #535353;
  margin-bottom: 0;
}
input.input {
  height: 65px;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.responseWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 710px; */
  margin: 200px auto;
  text-align: center;
}
.boxIcon {
  width: 9.375rem;
  height: 9.375rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxText {
  margin-top: 2.8125rem;
  margin-bottom: 4.25rem;
}
.boxText label {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 45px;
}
.boxText p {
  font-size: 1.25rem;
}
