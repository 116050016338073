.mb-20{
    margin-bottom: 25px;
}
.wrapper--terms .bg--banner{
    background-image: url('@/assets/images/top/banner--img-terms.jpg');
}
.wrapper--terms .bg--banner .title_h1--banner{
    font-size: 48px;
}
.main--terms{
    padding: 70px 0 50px;
    font-family: "Oswald", sans-serif;
}
.main--terms{
    font-size: 15px;
    color: #000;
}
.top-content--txt{
    margin-bottom: 30px;
    
}
.main--terms .title__h4{
    font-size: 16px;
    margin-bottom: 18px;
}
.main--terms .content--txt{
    margin-bottom: 50px;
}
@media (max-width: 767px){
    .wrapper--law .bg--banner,
    .bg--banner{
        height: 190px;
    }
    .wrapper--terms .bg--banner .title_h1--banner,
    .wrapper--law .title_h1--banner,
    .title_h1--banner {
        font-size: 26px;
    }
    .main--policy{
        font-size: 14px;
    }
    
}