.sharingDialog {
  width: 42.5rem;
  margin: auto;
  border-radius: 15px;
}
.boxInviteInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sharingDialog .title {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0px;
}
.sharingDialog .dialogContent {
  padding: 0px;
  overflow-y: hidden;
}
button.btnInvite {
  border-radius: 40px;
  width: 7.8125rem;
  height: 50px;
  font-weight: 700;
  line-height: 50px;
}
.boxSharingList {
  margin-top: 16px;
}
.boxTitle {
  display: flex;
  align-items: center;
  height: 59px;
}
.boxSharingList .boxSharingContent,
.boxSharingContent li {
  padding: 0px;
}
.boxSharingList li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #cecece;
  height: 100%;
  padding: 4px 15px;
  margin-right: 15px;
}
.boxSharingContent ul {
  width: 100%;
  max-width: none;
}
.boxMember {
  display: flex;
  align-items: center;
  height: 49px;
}
.boxMember:hover {
  background-color: #dedede69;
}
.boxMember li .member {
  flex: none;
}
.boxMember li .member span {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
.boxMember li .member:first-child {
  margin-right: 15px;
}
.inpInvite {
  padding: 0px 20px;
  font-size: 1rem;
}
.empty {
  width: 150px;
  height: 100%;
}
.empty:has(label) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cecece;
}
.boxAutocomplete {
  flex: 2;
}
.boxSelect {
  flex: 1;
}
.boxSelect > div {
  width: 100%;
  padding: 0px 20px;
}
.option {
  display: block;
  padding: 5px 10px;
  margin: 5px 0px;
}
.option:hover {
  background-color: var(--theme);
  color: var(--color-white);
  cursor: pointer;
}
@media (max-width: 1100px) {
  .sharingDialog .title {
    font-size: 1.3rem;
  }
}
@media (max-width: 780px) {
  .boxMember li .member span,
  .empty:has(label) label,
  .boxSelect > div > div [class~='MuiInputBase-input'] {
    font-size: 1.3rem;
  }
  .boxSelect div [class~='MuiInputBase-root'] img {
    top: 30px;
  }
  .sharingDialog {
    width: 100%;
  }
  .sharingDialog > div [class~='MuiPaper-root'] {
    margin: 0;
    padding: 15px !important;
  }
  .sharingDialog .title {
    font-size: 1.4rem;
  }
  span {
    font-size: 1.3rem !important;
  }
  .inpInvite {
    padding: 0px 20px 0px 0px;
    font-size: 1rem;
  }
}
