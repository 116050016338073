.left__sideBar.sideBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0px;
  height: 100vh;
  height: -webkit-fill-available;
  width: 250px;
  font-size: 0.875rem;
  font-weight: 600;
  z-index: 0;
  border-right: 1px solid #dedede;
  z-index: 1;
}
.menuWrapper {
  width: 100%;
  padding: 0 20px;
  height: calc(100% - 70px);
}
.sideBar a,
.sideBar .link {
  color: var(--color-white);
}
input.inpSearch {
  height: 34px;
}
input.inpSearch:focus {
  color: var(--color-white);
}
input.inpSearch,
input.inpSearch:focus,
span.icon {
  border-color: var(--color-white);
}
.menu {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  position: relative;
  overflow: auto;
  height: calc(100% - 160px);
}
.menu::before {
  position: absolute;
  content: '';
  top: -0.5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 25px);
  height: 1px;
  background-color: var(--color-white);
}
.menu li {
  position: relative;
  list-style: none;
  padding: 0px 12px;
}
.menu li:not(:last-child) {
  margin-bottom: 10px;
}
.menu li a,
.sideBar .link {
  text-decoration: none;
  display: block;
  height: 45px;
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  align-items: center;
  border-radius: 36px;
  padding-left: 12px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu .accordion {
  background-color: var(--theme);
  box-shadow: none;
  margin-bottom: 10px;
}
.menu .accordion .accordionSummary{
  padding: 0 12px;
  height: 45px;
  color: var(--color-white);
}

.menu li span, 
.menu .accordion span {
  margin-left: 13px;
}
.menu li a:hover,
.menu li .link:hover,
.menu li.active a {
  background-color: var(--color-white);
  color: var(--theme);
}
.menu li a:hover svg path,
.menu li .link:hover svg path,
.menu li.active svg path {
  stroke: var(--theme);
}
.menu li a svg.externalLink {
  width: 14px;
  height: 14px;
}
.menu li a:hover svg.externalLink path{
  color: var(--theme);
  stroke: var(--theme);
  fill: var(--theme);
}
.imgMenuexternalLink {
  margin-left: 5px;
}
.menu li a svg {
  width: 20px;
  height: 20px;
}
.menu li a svg.customIcon {
  fill: var(--color-white);
}

.menu li a:hover svg.customIcon path,
.menu li.active svg.customIcon path {
  fill: var(--theme);
}
.left__sideBar .settingAccount {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 70px;
  background-color: var(--color-white);
  width: 100%;
  padding: 0px 17px;
  position: absolute;
  bottom: 0;
  z-index: 9999;
}
.left__sideBar .settingAccount,
.settingAccount > label {
  cursor: pointer;
}
.settingAccount > img:first-child {
  width: 26px;
}
.settingAccount > img:last-child {
  width: 24px;
  cursor: pointer;
}
.settingAccount > label {
  flex: 1;
  padding-left: 15px;
}
.customMenu li,
.customMenu ul li.settingAccount label {
  font-weight: 600;
  font-size: 0.875rem;
}
.customMenu li:not(.settingAccount) a,
.customMenu li:not(.settingAccount) span {
  height: 50px;
  padding: 0 15px;
}
.customMenu li:not(.settingAccount) .menuItem,
a.outLink {
  color: #525252;
  padding: 0px 15px;
}
.customMenu li:last-child .menuItem {
  color: #e14646;
  padding: 0px 15px;
}
.customMenu li:not(.settingAccount) img {
  margin-right: 15px;
}
.customMenu ul li.settingAccount {
  position: relative;
  background-color: var(--color-white);
  height: 70px;
  box-shadow: 0px 4px 4px #0000000d;
}
.linkMenu {
  display: flex;
  text-decoration: none;
  width: 100%;
  align-items: center;
}
label.fullName {
  max-width: 149px;
  padding-left: 10px;
  position: relative;
  font-size: 0.9375rem;
}
.customMenu ul li.settingAccount .fullName {
  font-size: 0.9375rem;
}

.left__sideBar .inputPlaceholder {
  height: 34px;
  font-size: 0.875rem;
}
.left__sideBar .inputPlaceholder::placeholder {
  color: var(--color-white);
  font-weight: 600;
  opacity: 1;
}

.left__sideBar .inputPlaceholder::-ms-input-placeholder {
  color: var(--color-white);
  font-weight: 600;
}

.left__sideBar .inputPlaceholder::-ms-input-placeholder {
  color: var(--color-white);
  font-weight: 600;
}
button.hamburger,
button.hamburger:hover {
  position: fixed;
  background-color: var(--theme);
  color: var(--color-white);
  top: 15px;
  left: 15px;
  min-width: 30px;
  height: 30px;
  display: none;
  z-index: 1;
}
.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  background-color: #d40000;
  border-radius: 50%;
}
hr.line {
  margin: 0px 17px !important;
  border-color: #cecece;
  opacity: 1;
}
.menuActive {
  background-color: #e9e9e9 !important;
}
.logo {
  display: block;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.imgMenu {
  width: 35px;
}
.indentMenu {
  margin-left: 20px;
}
@media (max-width: 1100px) {
  .left__sideBar.sideBar {
    transition: left 0.3s ease;
    font-size: 1.077rem;
  }
  button.hamburger,
  button.hamburger:hover {
    display: flex;
  }
  label.fullName,
  .customMenu ul li.settingAccount .fullName {
    font-size: 1.077rem;
  }
  .customMenu li,
  .customMenu ul li.settingAccount label {
    font-size: 1.077rem;
  }
}
@media (max-width: 780px) {
  .left__sideBar.sideBar,
  label.fullName,
  .customMenu ul li.settingAccount .fullName {
    font-size: 1.3rem;
  }
}

@media (max-width: 600px) {
  .left__sideBar.sideBar {
    width: 100%;
  }
}
