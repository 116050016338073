label.label {
  margin-top: 20px;
  font-weight: 600;
  color: #414141;
  flex: 1;
}

@media (max-width: 780px) {
  label.label {
    font-size: 1.3rem;
  }
}
