@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* ==== */

.body-wrapper{
    overflow: hidden;
    position: relative;
}
.section--Page{
    height: 100vh;
    min-height: 950px;
    overflow: hidden;
}
.bg--all-page{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 950px;
    background-image:  url('@/assets/images/top/bg-banner.svg');
}
a:hover{
    text-decoration: none;
    opacity: 0.8;
}
/* ======= */
.loading--all-page{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: block;
    max-height: 100vh;
}
.loading--logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    position: relative;
    text-align: center;
}
.loading--logo .block--logo{
    display: inline-block;
}
.loading--logo .logo-loading{
    margin-right: 32px;
}
.loading--logo .text--logo{
    width: 274px;
}
.loading--logo .logo-loading,
.loading--logo .text--logo{
    position: relative;
    z-index: 10;
}

.remove--txt .logo-loading{

    opacity: 0;
    visibility: hidden;
    margin: 0;
}
.remove--txt .text--logo{
    width: 0;
    opacity: 0;
    visibility: hidden;
}
.loading--item{
    position: absolute;
    left: calc(50% - 103.5px);
    top: -38px;
    width: 207px;
    height: 207px;
    opacity: 0;
    visibility: hidden;
    scale: 0.7;
}
.remove--txt .loading--item{
    opacity: 1;
    visibility: visible;
    scale: 1;
}

.loading--item span{
    width: 96px;
    height: 96px;
    display: none;
    position: absolute;
    transition: all 0.3s ease;

}
.bg--ld-top,
.bg--ld-bottom{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    left: calc(50% - 92.5px);
    width: 185px;
    height: 92.5px;
    z-index: 100;
    transition: all 0.8s ease-out;
}
.remove-bg--ld .bg--ld-top,
.remove-bg--ld .bg--ld-bottom{
    opacity: 1;
    visibility: visible;
}
.bg--ld-top{
    top: calc(50% - 92.5px);
}
.remove-bg--ld .bg--ld-top{
    top: -30%;
}
.bg--ld-bottom{
    bottom: calc(50% - 92.5px);
}
.remove-bg--ld .bg--ld-bottom{
    bottom: -30%;
}

.bg--ld-bottom img{
    position: relative;
    top: -46px;
    right: -46px;
}
.remove--bg .bg--ld-top img,
.remove--bg .bg--ld-bottom img{
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.remove--bg .bg--ld-top img{
    transform: rotate(45deg);
}
.remove--bg .bg--ld-bottom img{
    transform: rotate(45deg);
}
/* ========= */

/* =========== */
.loading--all-page .block-top,
.loading--all-page .block-bottom{
    left: 0;
    width: 100%;
    position: absolute;
    height: 50%;
    overflow: hidden;
    background-color: #fff;
    transition: all 0.8s cubic-bezier(0.29, -0.01, 0.94, 0.88);
}
.loading--all-page .block-top{
    top: 0;
}
.loading--all-page .block-bottom{
    bottom: 0;
}
.loading--all-page .block-top img{
    position: relative;
    top: -200%;
}
.loading--all-page .block-bottom img{
    position: relative;
    bottom: -100%;
}
.loading--bg .block-top img{
    top: 0;
}
.loading--bg .block-bottom img{
    bottom: 0;
}
.loading--bg2 .block-top img,
.loading--bg2 .block-bottom img{
    opacity: 0;
}
.remove--bg .block-top{
    top: -100%;
    height: 0;
}
.remove--bg .block-bottom{
    bottom: -100%;
    height: 0;
}
.block-top::after,
.block-bottom::before{
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #1DA8AF;
    opacity: 0;
}
.block-top::after{
    bottom: 0px;
}
.block-bottom::before{
    top: 0px;
}
.line--bg .block-top::after,
.line--bg .block-bottom::before{
    opacity: 1;
}

.loading--bg2 .loading--logo .logo-loading, 
.loading--bg2 .loading--logo .text--logo{
    opacity: 0;
    visibility: hidden;
}
/* ======= */
.head--top-menu{
    position: fixed;
    left: 0;
    top: -100%;
    width: 100%;
    z-index: 100;
    background-color: #000000;
}
.top--active .head--top-menu,
.all-active .head--top-menu{
    top: 0;
}
.head--top-menu .menu--wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-left: -15px;
    margin-right: -15px;
}
.menu--head ul,
.menu--right ul{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;
    flex: 2 1;
    position: relative;
    font-family: "Oswald", sans-serif;
}
.menu--head{
    width: 100%;
    max-width: 665px;
}
.menu--head ul{
    position: relative;
    overflow: hidden;
}
.menu--head ul::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: -50px;
    width: 110px;
    height: 35px;
    background-color: #3A7CAB;
    border-radius: 35px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 0;
    visibility: hidden;
}
.active-whatIs .menu--head ul::after,
.active-feature .menu--head ul::after,
.active-value .menu--head ul::after,
.active-news .menu--head ul::after,
.active-contact .menu--head ul::after{
    opacity: 1;
    bottom: 5px;
    visibility: visible;
}
.active-whatIs .menu--head ul::after{
    left: 0;
}
.active-feature .menu--head ul::after{
    left: calc(16.6666% * 1);
}
.active-value .menu--head ul::after{
    left: calc(16.6666% * 2);
}
.active-news .menu--head ul::after{
    left: calc(16.6666% * 3);
}
.active-contact .menu--head ul::after{
    left: calc(16.6666% * 4);
}
.menu--head ul li{
    flex: 2 1;
    position: relative;
    z-index: 5;
}
.menu--head ul li a,
.menu--right ul li a{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu--head ul li a{
    font-size: 16px;
    height: 45px;
    width: 100%;
    position: relative;
}

.menu--right ul li:not(:last-child){
    margin-right: 37px;
}
.menu--right ul li a{
    font-size: 14px;
}
.menu--right ul li:last-child a{
    height: 40px;
    width: 130px;
    background: rgb(38,161,155);
    background: linear-gradient(95deg, rgba(38,161,155,1) 0%, rgba(53,165,212,1) 100%);
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    border-radius: 5px;
}


@media (min-width: 1450px) {
    .container--banner{
        max-width: 1140px;
    }
}



/* ======= */
.text-center{
    text-align: center;
}
.bg--all-page{
    background-size: cover;
    background-position: center 800px;
    background-repeat: no-repeat;
    transition: all 1.6s cubic-bezier(0, 0.62, 0, 0.99);
}
.top--active .bg--all-page{
    background-position: center center;
}

@media (min-width: 1990px){
    .lp--banner{
        padding-bottom: 120px;
    }
}
.banner--top{
    position: relative;
    max-height: 57%;
    min-height: 57%;
    display: flex;
}
.lp--banner .banner--top{
    max-height: 54%;
    min-height: 54%;
}
.all-active .bg--all-page{
    background-position: center center;
}
.all-active .bg--all-page .banner--top{
    max-height: 100%;
    scale: 1;
}
.lp--banner .banner--top{
    opacity: 0;
    z-index: -1;
}
.banner--top .image--bg{
    display: flex;
    align-items: self-end;
    max-width: 100%;
    flex: 0 0 100%;
}
.bg--all-page .banner--top .image--bg {
    overflow: hidden;
}
.active-whatIs .image--banner-what{
    position: relative;
    transition: all 0.4s cubic-bezier(0, 0.62, 0, 0.99);
}
.active-whatIs .image--banner-what::before{
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 300px;
    background-color: #000000;
    bottom: calc(100% - 21%);
}



.bg--all-page .banner--top .image--bg img{
    top: 0;
    position: relative;
}
.top--active .banner--top .image--bg img{
    top: 20%;
}
.banner--top .image--mac{
    position: absolute;
    left: calc(50% - 405.5px);
    bottom: -19px;
}

.banner--bottom{
    position: absolute;
    text-align: center;
    top: 150%;
    scale: 0.7;
    left: 0;
    width: 100%;
}
.top--active .banner--bottom{
    top: calc(50% + 90px);
    width: 100%;
    scale: 1;
}
.image--banner-what{
    top: -100%;
    /* scale: 0.95; */
    scale: 0.7;
}
.top--active .image--banner-what{
    top: 0;
    scale: 1;
}
.image--banner-what .image--mac{
    scale: 0.7;
}
.top--active .image--banner-what .image--mac{
    scale: 1;
}
.banner--bottom .titleH1{
    font-size: 40px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 15px;
    font-weight: 400;
    color: #000000;
}
.banner--bottom .titleH1 span{
    color: #3A7CAB;
}
.banner--bottom .description-txt{
    width: 100%;
    max-width: 560px;
    background-color: #000000;
    padding: 25px 15px;
    color: #fff;
    font-size: 18px;
    line-height: 38px;
    font-family: "Oswald", sans-serif;
    margin: 0 auto 25px;
}
.banner--bottom .banner--btn{
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
}
.banner--bottom .banner--btn a{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 130px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.btn--ctact{
    margin-right: 7px;
    font-weight: bold;
    background: rgb(38,161,155);
    background: linear-gradient(95deg, rgba(38,161,155,1) 0%, rgba(53,165,212,1) 100%);
}
.btn--what{
    margin-left: 7px;
    font-weight: 500;
    background-color: #000000;
    font-family: "Oswald", sans-serif;
}
.all-active .lp--banner .banner--top{
    display: none !important;
}
.all-active .banner--top .image--mac{
    bottom: -100%;
    opacity: 0;
}
.active-contact.all-active .banner--top .image--mac img{
    opacity: 0;
}
.all-active .banner--bottom {
    opacity: 0;
    visibility: hidden;
}
.all-active .banner--bottom{
    top: 110%;
    height: 0;
}
.reserved-i{
    width: 215px;
    height: 45px;
    position: absolute;
    left: -50%;
    bottom: 180px;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    font-size: 10px;
    color: #696969;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    font-weight: bold;
    font-family: "Oswald", sans-serif;
}
.top--active .reserved-i{
    left: -85px;
    opacity: 1;
    visibility: visible;
}
/* ============= */
.image--banner-what .image--bg img{
    clip-path: circle(68% at 50% 0);
    transition: all 0.7s ease-out;
}
@media (min-width: 1500px){
    .image--banner-what .image--bg img{
        clip-path: circle(72% at 50% 0);
    }
}

/* =================== */
.transition-02s{
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.transition-04s{
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.transition-06s{
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}
.transition-06cubic{
    -webkit-transition: all 0.6s cubic-bezier(0.42, 0, 0, 1.01);
    -moz-transition: all 0.6s cubic-bezier(0.42, 0, 0, 1.01);
    -o-transition: all 0.6s cubic-bezier(0.42, 0, 0, 1.01);
    transition: all 0.6s cubic-bezier(0.42, 0, 0, 1.01);
}
.transition-08s{
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
}
.transition-1s{
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.transition-15s{
    -webkit-transition: all 1.5s ease;
    -moz-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    transition: all 1.5s ease;
}
.transition--06s-slow{
    transition: all 0.6s cubic-bezier(0, 0.62, 0, 0.99);
}
.transition--1s-slow{
    transition: all 0.8s cubic-bezier(0, 0.62, 0, 0.99);
}
.transition--2s-slow{
    transition: all 1.6s cubic-bezier(0, 0.62, 0, 0.99);
}
.h2-title--head{
    font-size: 36px;
    font-family: "Oswald", sans-serif;
    color: #fff;
    font-weight: 400;
}
/* ==== */
.active-whatIs .image--banner-what{
    top: 0;
    scale: 1;
    max-height: 100%;
    height: 100%;
}
.active-feature .image--banner-what{
    top: 0;
    scale: 1;
    max-height: 100%;
    height: 100%;
}
.active-whatIs .image--banner-what .image--bg img{
    clip-path: circle(100.0% at 50% 13%);
}
.lp--whatIs{
    padding: 90px 0 20px;
    position: relative;
    overflow: hidden;
    z-index: 10;
}
.lp--whatIs .image--bg-whatIs{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.lp--whatIs .h2-title--head{
    margin-bottom: 27px;
    position: absolute;
}
.lp--whatIs .h2--whatIs{
    top: -130px;
    width: 100%;
}
.lp--whatIs .h2--feature{
    left: -100%;
    padding-left: 7%;
    margin-bottom: 40px;
    opacity: 0;
    visibility: hidden;
}
.lp--whatIs .image--whatIs{
    /* max-width: 936px; */
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 100%;
    bottom: -280%;
}
.lp--whatIs .What--bottom-txt{
    position: absolute;
    bottom: 10%;
    left: calc(50% - 280px);
    width: 100%;
    max-width: 560px;
    background-color: #000000;
    padding: 15px 15px 22px;
    color: #fff;
    font-size: 16px;
    line-height: 38px;
    font-family: "Oswald", sans-serif;
    opacity: 0;
    visibility: hidden;
}
.all-active.active-whatIs .lp--whatIs .image--whatIs{
    bottom: 0;
    position: relative;
}
.all-active.active-whatIs .lp--whatIs .image--whatIs,
.all-active.active-whatIs .lp--whatIs .What--bottom-txt{
    opacity: 1;
    visibility: visible;
}
.all-active.active-feature .lp--whatIs .What--bottom-txt,
.all-active.active-value .lp--whatIs .What--bottom-txt,
.all-active.contact-value .lp--whatIs .What--bottom-txt{
    bottom: -100%;
}

.all-active.active-whatIs .lp--whatIs .h2--whatIs{
    top: 0;
    position: relative;
}
.all-active.active-feature .lp--whatIs .h2--feature {
    left: 0;
    position: relative;
    opacity: 1;
    visibility: visible;
}
.all-active.active-whatIs .lp--whatIs .What--bottom-txt{
    left: 0;
    bottom: 0;
    max-width: 100%;
}
.lp--whatIs .What--bottom-txt .text--txt{
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    max-width: 945px;
    margin-left: auto;
    margin-right: auto;
    font-size: 15px;
}

.block-scroll--whatIs,
.block-scroll--feature,
.block-scroll--value,
.block-scroll--value-detail,
.block-scroll--news,
.block-scroll--news-detail,
.block-scroll--news-end{
    position: absolute;
    left: auto;
    width: 100%;
    top: calc(55vh - 150px);
    right: -50%;
    max-width: 150px;
    transition: all 0.6s ease;
    z-index: 100;
}

.scroll--nt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 83px;
    cursor: pointer;
    max-width: 250px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
}
.scroll--nt:hover{
    opacity: 0.8;
}
.scroll--nt span{
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
}
.top--active .block-scroll--whatIs,
.active-whatIs .block-scroll--feature,
.active-feature .block-scroll--value,
.active-value .block-scroll--news-detail,
.active-news-detail .block-scroll--news-end{
    right: 0;
}

.block-scroll--whatIs .scroll--nt, .block-scroll--news-end .scroll--nt{
    color: #000000;
}



.active-value .bg--all-page{
    background-image: url('@/assets/images/top/img-bg-feature.svg') !important;
}

.image--banner-feature::before{
    position: absolute;
    content: "";
    left: 0;
    top: -200px;
    width: 100%;
    height: 350px;
    background-color: #07111a;
}

.image--banner-feature,
.image--banner-value,
.image--banner-news,
.image--banner-contact{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: flex-end;
}
.image--banner-news{
    height: 100vh;
}
.image--banner-contact{
    align-items: flex-start;
}
.active-contact .image--banner-news{
    opacity: 1;
    visibility: visible;
    z-index: 10;
}
.active-news .image--banner-contact{
    opacity: 1;
    visibility: visible;
    z-index: 8;
}
.active-feature .image--banner-feature,
.active-value .image--banner-value,
.active-news .image--banner-news,
.active-contact .image--banner-contact{
    opacity: 1;
    visibility: visible;
    z-index: 10;
}



.image--banner-feature img{
    position: relative;
    top: 350px;
}
.active-feature .image--banner-feature img{
    top: 0;
}
.image--banner-value img{
    position: relative;
    bottom: 100%;
    scale: 0.5;
}
.image--banner-news img{
    position: relative;
    top: -40%;
    scale: 1;
}


.bg-news-contact{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
}
.active-contact .bg-news-contact{
    top: -40%;
}
.image--banner-contact img.bg-contact{
    position: relative;
    top: 100%;
}
@media (max-width: 1500px){
    .bg-news-contact{
        scale: 1.7;
    }
}
.active-contact .image--banner-contact img.bg-contact{
    top: 0;
}

@media (max-width: 1300px){
    .image--banner-feature{
        align-items:initial;
    }
    .image--banner-feature img{
        object-fit: cover;
    }
}
@media (min-height: 970px){
    .image--banner-feature{
        align-items:initial;
    }
    .image--banner-feature img{
        object-fit: cover;
    }
}
@media (max-height: 920px){
    .active-feature .block-scroll--value{
        position: absolute;
        bottom: 23px;
    }
}

.top__scroll-slider{
    overflow-x: auto;
    max-width: calc(100% - 7% - 170px);
    margin-left: 40px;
    position: absolute;
    left: 105%;
    top: 104px;
    visibility: hidden;
    opacity: 0;
    height: 20px;
    z-index: 20;
}
.active-feature .top__scroll-slider{
    opacity: 1;
    visibility: visible;
    left: calc(7% + 110px);
}

.slider--wrapper{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 100%;
    color: #fff;
}
.active-feature .slider--wrapper{
    opacity: 1;
    visibility: visible;
    left: 0;
    position: relative;
}
.slider--feature{
    overflow-x: auto;
    padding: 0 6.5%;
    margin-bottom: 17px;
    transition: all 0.1s;
    will-change: transform;
    user-select: none;
    cursor: pointer;
}
.slider__Feature{
    display: flex;
    flex-wrap: nowrap;
    position: relative;
}
.slider__Feature::after{
    content: "";
    display: table;
    clear: both;
}
.slider--feature .item--li{
    padding: 10px;
    /* float: left; */
    flex: 0 0 auto;
}
.item-feature{
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    position: relative;
}
.item-feature .cate--top{
    position: absolute;
    left: 0;
    top: 42px;
    padding: 0 20px;
    font-family: "Oswald", sans-serif;
}
.item-feature .cate--top .cat{
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
}
.item-feature .cate--top .cat span{
    margin-left: 10px;
}
.item-feature .cate--top .txt-text{
    font-size: 14px;
    line-height: 2.04;
}
.item-feature .bottom--item{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 180px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    background-color: rgb(0 0 0 / 80%);
    border-radius: 0 0 10px 10px;
    padding: 17px 15px 17px;
    line-height: 1.8;
}
.item-feature .bottom--item span{
    color: #79CAD7;
    font-size: 12px;
    font-family: "Inter", sans-serif;
}
/* ========================= */
.active-value .block-scroll--value-detail,
.active-value.active-value-detail .block-scroll--news,
.active-news .block-scroll--news-detail{
    bottom: 15px;
    left: auto;
}
.active-value.active-value-detail .block-scroll--news{
    z-index: 100;
}
.active-value.active-value-detail .block-scroll--value-detail{
    bottom: -100%;
}
.title--head-option2{
    position: absolute;
    text-align: center;
    bottom: -50%;
    left: calc(50% - 312px);
    text-align: center;
    font-family: "Oswald", sans-serif;
    opacity: 0;
    visibility: hidden;
    color: #fff;
}
.title--head-option3{
    bottom: auto;
    top: -350px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 50px 0 25px;
}
.title--head-option2 .h2--value{
    font-size: 96px;
    line-height: 1;
    /* margin-bottom: 20px; */
    text-align: center;
    font-weight: 400;
}
.title--head-option2 .text--txt{
    font-size: 16px;
    font-weight: 500;
}
.title--head-option2 .text--txt span{
    color: #6FB7C3;
}
.active-value .title--head-value,
.active-news .title--head-news{
    opacity: 1;
    visibility: visible;
}
.title--head-contact{
    scale: 0.6;
}
.active-contact .title--head-contact {
    opacity: 1;
    visibility: visible;
    top: 0;
    scale: 1;
    position: relative;
}

.value--detail{
    position: absolute;
    bottom: -85%;
    left: 0;
    width: 100%;
    height: 53%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    background-color: rgb(48 102 140 / 50%);
    background-image: url('@/assets/images/top/PRIMARY-WALL.png');
    background-position: center center;
    color: #fff;
    padding: 63px 85px 63px 85px;
    font-family: "Oswald", sans-serif;
}
.active-value-detail .value--detail{
    visibility: visible;
    opacity: 1;
    z-index: 10;
    bottom: 0;
}


.active-value.active-value-detail .image--banner-value::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
}
.active-value.active-value-detail .image--banner-value img{
    scale: 1.85;
    bottom: 40%;
}
.active-value.active-value-detail .title--head-value{
    bottom: calc(70% - 30px);
}

.value--detail .body--form{
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1270px;
    margin: 0 auto;
}
.value--detail .body--form .tab-wrapper{
    max-width: 22%;
    flex: 0 0 22%;
}
.value--detail .body--form .content-wrapper{
    max-width: calc(100% - 22%);
    flex: 0 0 calc(100% - 22%);
    padding-left: 50px;
    height: 100%;
    overflow: auto;
}
.body--form .tab-wrapper .tab-link{
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 51px;
    border-radius: 35px;
    color: #fff;
    font-size: 16px;
    font-family: "Oswald", sans-serif;
}
.body--form .tab-wrapper ul{
    margin-bottom: 0;
    padding: 0;
}
.value--detail .body--form .tab-wrapper .tab-link.active{
    background-color: #fff;
    color: #000000;
}
.body--content-wp{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.body--content-wp .image--ct-value{
    max-width: 29.5%;
    flex: 0 0 29.5%;
    border-radius: 50px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
    overflow: hidden;
}
.body--content-wp .content--ct-value{
    max-width: calc(100% - 29.5%);
    flex: 0 0 calc(100% - 29.5%);
    padding-left: 50px;
}
.content--ct-value .head--top{
    border-bottom: 10px solid #fff;
    padding-block: 15px;
    margin-bottom: 12px;
}
.content--ct-value .title--dt-head{
    font-size: 24px;
    margin-bottom: 7px;
    font-weight: 400;
}
.content--ct-value .des--txt-i{
    font-size: 16px;
    font-weight: bold;
}
.content--ct-value .txt--content-i{
    font-size: 12px;
    line-height: 2.28;
}
/* ============= */
.news--detail{
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    margin-top: 258px;
    top: 110vh;
}
.active-news-detail .news--detail{
    visibility: visible;
    opacity: 1;
    z-index: 10;
    top: 0;
    position: relative;
}
.news--detail .main--xBody{
    background-color: #fff;
    color: #fff;
    padding: 60px 86px 53px 120px;
    min-height: 620px;
}
.active-news.active-news-detail .image--banner-news img{
    scale: 1.4;
    top: 0%;
}
.title--head-news{
    bottom: auto;
    top: -50%;
}
.title--head-news,
.title--head-value{
    width: 100%;
    max-width: 624px;
}
.active-news.active-news-detail .title--head-news{
    top: calc(9% - 4px);
}
/* ============= */
.active-contact.active-contact-detail .image--banner-contact img{
    scale: 1.4;
    top: 10%;
}
.active-contact.active-contact-detail .title--head-option2{
    bottom: calc(80% - 73px);
}
.news--detail .body--form{
    display: flex;
    align-items: center;
    height: 100%;
    color: #000000;
    max-width: 860px;
    margin: 0 auto;
    position: relative;
}
.news--detail .body--form .tab-wrapper {
    max-width: 22%;
    flex: 0 0 22%;
    position: relative;
}

.news--detail .body--form .content-wrapper {
    /* max-width: calc(100% - 22%);
    flex: 0 0 calc(100% - 22%); */
    /* padding-left: 90px; */
    max-width: 100%;
    flex: 0 0 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.news--detail .body--form .tab-wrapper .tab-link{
    color: #000000;
}
.news--detail .body--form .tabs{
    position: relative;
}
.news--detail .body--form .tabs::after{
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #D8D8D8;
}
.news--detail .body--form .tab-link{
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    padding-left: 35px;
    font-weight: 350;
    position: relative;
}
.news--detail .body--form .tab-link.active{
    color: #005BAC;
    font-weight: bold;
    padding-left: 50px;
}
.news--detail .body--form .tab-link::before{
    content: "";
    position: absolute;
    left: 35px;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    background-color: #005BAC;
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}
.news--detail .body--form .tab-link.active::before{
    opacity: 1;
}

.item--news{
    padding:  15px 0 15px;
    display: flex;
    /* align-items: center; */
    font-family: "Oswald", sans-serif;
    /* background-color: #fafafa; */
}
/* .item--news:nth-child(2n){
    background-color: #fff;
} */
.item--news:not(:last-child){
    margin-bottom: 10px;
}
.item--news .image-n{
    max-width: 22%;
    flex: 0 0 22%;
}
.item--news .image-n img{
    border-radius: 5px;
    height: 135px;
    object-fit: cover;
}
.item--news .content-n{
    max-width: calc(100% - 22%);
    flex: 0 0 calc(100% - 22%);
    padding-left: 20px;
    /* width: 100%; */
    /* display: flex;
    align-items: center; */
    color: #000000;
    transition: all 1s ease;
    font-size: 16px;
}
.item--news:hover .content-n{
    color: #3a7cab;
}
.item--news .date--cat{
    align-items: center;
    display: flex;
    list-style: none;
    margin-bottom: 10px;
    padding: 0;
}

.item--news .date--cat li{
    color: #898989;
    font-size: 12px;
}
.item--news .date--cat li:not(:last-child) {
    margin-right: 20px;
}
.item--news .date--cat .cat-i{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 20px;
    border-radius: 38px;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
}
.cat-PRESS{
    background-color: #3A7CAB;
}
.cat-MEDIA{
    background-color: #1DA8AF;
}
.cat-AWARD{
    background-color: #0D93AC;
}

.item--news .content--text{
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #DBDBDB;
    /* max-width: calc(100% - 80px);
    flex: 0 0 calc(100% - 80px); */
    
}
.item--news .content--text .txt-i{
    max-width: calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
    line-height: 1.65;
    padding-left: 10px;
    color: #000000;
    align-content: center;
}
.item--news .content--text .txt-i .text-excerpt{
    font-size: 12px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.item--news .content--text .icon-i{
    max-width: 60px;
    flex: 0 0 60px;
    padding-right: 6px;
    text-align: right;
}

.active-news-detail .item--news{
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    text-decoration: none;
}
.active-news-detail .delay-01s{
    animation-delay: 0.22s;
}
.active-news-detail .delay-02s{
    animation-delay: 0.26s;
}
.active-news-detail .delay-03s{
    animation-delay: 0.32s;
}
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(100%, 0, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}
/* ============= */

.contact--form{
    position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}
.active-contact .contact--form{
    visibility: visible;
    opacity: 1;
    z-index: 10;
    top: 0;
    position: relative;
    height: 100%;
}
.active-contact .section--Page,
.active-news-detail .section--Page{
    height: auto;
    padding-bottom: 0;
}
.active-contact .scroll-contact{
    padding-bottom: 35px;
}


.contact--form .form--contact{
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 15px;
    padding: 16px 70px 15px 70px;
    font-family: "Oswald", sans-serif;
}
.contact--form .form--group{
    margin-bottom: 5px;
    max-width: 475px;
    margin-left: auto;
    margin-right: auto;
}
.contact--form label{
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.contact--form label span{
    width: 23px;
    text-align: center;
    color: #FF0000;
    margin-right: 4px;
    display: block;
    height: 23px;
}
.flex--block{
    display: flex;
    flex-wrap: wrap;
    padding-left: 27px;
}
.flex--block .cel-6{
    max-width: 50%;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
}
.flex--block .cel-6 .form-control{
    margin-left: 10px;
}
.flex--block .cel-6.centent--end{
    justify-content: flex-end;
}
.flex--block .cel-6 span{
    font-size: 12px;
    color: #000;
}
.contact--form .form-control{
    border: none;
    border-bottom: 1px solid #13232E;
    width: calc(100% - 27px);
    margin-left: 27px;
    border-radius: 0;
    font-size: 14px;
    height: 38px;
    padding-left: 17px;
    color: #000000;
}
.form-control:focus{
    outline: none;
    box-shadow: none;
}
.contact--form .mw-300{
    max-width: 300px;
}
.contact--form .mw-180{
    max-width: 180px;
}
.contact--form textarea.form-control{
    height: auto;
    border-radius: 6px;
    border: 1px solid #13232E;
    resize: none;
}
.contact--form .bottom-form{
    margin-top: 20px;
}
.contact--form .bottom-form hr{
    max-width: 495px;
    margin: 0 auto 28px;
}
.contact--form .bottom-form .boxAccept{
    font-size: 14px;
    color: #000000;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact--form .bottom-form .boxAccept a{
    font-weight: bold;
    text-decoration: underline;
    color: #3A7CAB;
    margin-right: 10px;
}
.contact--form .bottom-form .boxAccept .MuiCheckbox{
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 15px;
}
.contact--form .bottom-form .boxAccept .checkbox-id{
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 15;
}
.contact--form .bottom-form .boxAccept img{
    display: block;
}
.contact--form .bottom-form .boxAccept .MuiTouchRipple{
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background-color: #D2D2D2;
}
.contact--form .bottom-form .boxAccept input[type='checkbox']:checked +  img{
    position: relative;
    z-index: 10;
}


.contact--form .bottom-form .bottom--form-ct{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 160px;
    height: 60px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    background-color: #3A7CAB;
    margin: 0 auto;
}


.footer-id{
    background-color: #fff;
    padding: 65px 70px 40px;
    font-family: "Oswald", sans-serif;
}
.footer-id .top--logo{
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid #CACACA;
}
.footer-id .ul--menu-bt{
    margin: 0;
    padding: 0;
    width: 100%;
}
.footer-id .ul--menu-bt li{
    width: 100%;
    list-style-type: none;
}
.footer-id .ul--menu-bt li:not(:last-child){
    margin-bottom: 5px;
}
.footer-id .ul--menu-bt li a{
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 45px;
    font-size: 16px;
    color: #434343;
    font-family: "Oswald", sans-serif;
}
.footer-id  .right--ft{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;
}
.footer-id  .right--ft p{
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    color: #004EA2;
    padding-left: 15px;
}










@media (max-width: 1400px){
    .active-whatIs .bg--all-page .image--banner-what img,
    .bg--all-page .image--banner-feature img,
    .bg--all-page .image--banner-value img,
    .bg--all-page .image--banner-news  img,
    .bg--all-page .image--banner-contact  img{
        height: 100%;
        object-fit: cover;
    }
}
@media (max-width: 1024px){
    .top--active .banner--top .image--bg img{
        top: 0;
    }
}


.mb-50{
    margin-bottom: 50px;
}
.imgRotate180{
    rotate: 180deg;
}
/* ============= */
.content-wrapper .tab-content {
    display: none;
}
.content-wrapper .tab-content.active {
    display: block;
}

/* =================== */
.none-scroll-bar::-webkit-scrollbar {
    display: none;
}
  

.none-scroll-bar {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
@media (max-width: 991px){
    .none-scroll-bar.scrollSync ::-webkit-scrollbar {
        display: block;
    }
    .none-scroll-bar.scrollSync  {
        -ms-overflow-style: thin; 
        scrollbar-width: thin;  
    }
}
.placeholder-color::placeholder { 
	color: #AAAAAA;
	opacity: 1;
}

.placeholder-color::-ms-input-placeholder {
	color: #AAAAAA;
}

.placeholder-color::-ms-input-placeholder { 
	color: #AAAAAA;
}


.slider__scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background: #F5F5F5 ;
	border-radius: 10px ;
}

.slider__scrollbar::-webkit-scrollbar
{
	width: 10px ;
	background: transparent ;

}

.slider__scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px ;
	background: #1DA8AF ;
    border: 4px solid #F5F5F5 ;
}

/* .slider__scrollbar::-webkit-scrollbar-thumb:hover {
    background: #fff;
} */

.slider__scrollbar::-webkit-scrollbar:horizontal {
    height: auto;
}
/* .slider__scrollbar::-webkit-scrollbar-thumb:horizontal {
    background: #1DA8AF;
} */

.button--viewmore{
    position: absolute;
    right: 0;
    bottom: 100%;
}
.button--viewmore a{
    text-decoration: none;
    font-size: 16px;
    color: #fff ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    background-color: #000000;
    border-radius: 3px;
    font-family: "Oswald", sans-serif;
    transition: all 0.3s ease;
}
.button--viewmore a:hover{
    background-color: #3a7cab;
}


.scrollSP {
    display: none;
}

@media (max-width: 1024px){
    .bg--all-page .banner--top .image--bg::before{
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        top: 0;
        height: 30%;
        background-color: #000000;
    }
}
@media (max-width: 991px){
    .top__scroll-slider{
        display: none;
    }
    .value--detail .body--form,
    .news--detail .body--form{
        flex-wrap: wrap;
    }
    .value--detail .body--form .tab-wrapper,
    .value--detail .body--form .content-wrapper,
    .news--detail .body--form .tab-wrapper,
    .news--detail .body--form .content-wrapper{
        max-width: 100%;
        flex: 0 0 100%;
    }
    .value--detail .body--form .tab-wrapper ul{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .value--detail .body--form .tab-wrapper .tab-link{
        max-width: 220px;
        margin: inherit;
    }
    .news--detail .body--form .tabs{
        display: flex;
    }
    .news--detail .body--form .tab-link{
        flex: 1 auto;
        width: auto;
    }
    .value--detail .body--form .content-wrapper{
        padding-left: 0;
    }
    .body--content-wp .image--ct-value{
        border-radius: 20px;
    }
    .news--detail .body--form .tabs::after{
        height: 1px;
        width: 100%;
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
    }
    .news--detail .body--form .content-wrapper{
        padding-left: 0;
    }

    
    .lp--whatIs .image--whatIs{
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 245px;
    }
}
@media (max-width: 780px){
    .banner--bottom .titleH1 span{
        font-size: 32px !important;
    }
}
@media (max-width: 767px){
    .section--Page,
    .bg--all-page{
        height: 100%;
        min-height: 715px;
    }
    .h2-title--head{
        font-size: 27px;
    }
    .banner--bottom .titleH1
    {
        font-size: 32px;
    }
    
    .banner--bottom .description-txt{
        padding: 11px 8px;
        font-size: 13px;
        line-height: 2;
        max-width: 450px;
    }
    .lp--whatIs .What--bottom-txt .text--txt{
        font-size: 13px;
        line-height: 2;
    }


    .image--banner-what .image--mac{
        left: auto;
        text-align: center;
    }
    .image--banner-what .image--mac img{
        max-width: 85%;
        margin: 0 auto;
    }

    .lp--whatIs .image--whatIs{
        bottom: -500px;
    }
    .all-active.active-whatIs .lp--whatIs .image--whatIs{
        bottom: -55px;
    }

    .button--viewmore a{
        font-size: 14px;
    }
    .item-feature{
        max-width: 250px;
        min-width: 250px;
    }
    .item-feature .bottom--item,
    .item-feature .cate--top .txt-text{
        font-size: 12px;
    }
    .item-feature .cate--top{
        top: 17px;
    }
    .item-feature .bottom--item span{
        font-size: 9px !important;
    }
    .item-feature .cate--top .cat span{
        font-size: 16px !important;
    }

    .title--head-news, .title--head-value{
        max-width: 538px;
    }
    .title--head-option2{
        left: calc(50% - 269px);
    }
    .title--head-option2 .h2--value{
        font-size: 50px;
    }
    .active-value.active-value-detail .title--head-value{
        bottom: calc(83% - 30px);
    }
    .title--head-option2 .h2--value{
        font-size: 43px;
    }
    .active-news.active-news-detail .title--head-news{
        top: calc(10% - 4px);
    }
    .title--head-option2 .text--txt{
        font-size: 14px;
    }

    .value--detail{
        height: 70%;
        padding: 30px 30px 30px 30px;
    }
    
    .value--detail .body--form .content-wrapper,
    .news--detail .body--form .content-wrapper{
        padding: 0;
    }
    .body--content-wp .content--ct-value{
        padding-left: 25px;
    }

    .news--detail{
        margin-top: 70px;
    }
    .news--detail .main--xBody{
        padding: 50px 30px 30px 30px;
        min-height: 320px;
    }

    

    .lp--whatIs .What--bottom-txt{
        left: calc(50% - 40%);
        max-width: calc(100% - 20%);
    }

    /* == */
    .top--active .block-scroll--whatIs{
        bottom: 0;
    }
    .block-scroll--news-end{
        top: 115px;
    }
    /* == */
    .block-scroll--feature, .block-scroll--value, .block-scroll--value-detail, .block-scroll--news, .block-scroll--news-detail, .block-scroll--news-end{
        max-width: 80px;
    }
    .scroll--nt{
        height: 60px;
    }
}
.m-sp575{
    display: none;
}
@media (max-width: 575px){
    .m-pc575{
        display: none;
    }
    .m-sp575{
        display: block;
    }
    .loading--all-page{
        max-height: 100%;
        overflow: hidden;
    }
    .loading--all-page .block-bottom{
        display: flex;
        align-items: flex-end;
    }

    .section--Page, .bg--all-page{
        min-height: 100%;
    }
    .body-wrapper{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .image--banner-what{
        max-height: 350px;
        min-height: 350px;
    }
    .top--active .banner--bottom{
        top: calc(50% + 25px);
    }
    .top--active .block-scroll--whatIs{
        bottom: 0;
        padding: 4px 0;
        background-color: #fff;
    }
    .reserved-i{
        display: none;
    }
    .banner--bottom .description-txt{
        max-width: calc(100% - 40px);
    }
    .button--viewmore{
        bottom: calc(100% + 3px);
    }
    .news--detail{
        margin-top: 60px;
    }
    .news--detail .body--form .tab-link,
    .news--detail .body--form .tab-link.active{
        padding-left: 0;
    }
    .news--detail .body--form .tabs {
        justify-content: center;
    }
    .news--detail .body--form .tab-link{
        flex: 0 auto;
        margin: 0 15px;
        font-size: 14px;
    }
    .news--detail .body--form .tab-link::before{
        left: 0;
        top: 40px;
        width: 100%;
        height: 1px;
    }

    .title--head-option2{
        left: 0;
        width: 100%;
        padding: 0 13px 0 20px;
    }
    .title--head-option2 .h2--value{
        font-size: 28px;
    }
    .active-value.active-value-detail .title--head-value{
        bottom: calc(86% - 13px);
    }
    .value--detail{
        height: 80%;
    }
    .value--detail .body--form .tab-wrapper{
        position: absolute;
        top: 195px;
        left: 0;
        width: 100%;
    }
    .value--detail .body--form .tab-wrapper ul{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .value--detail .body--form .tab-wrapper .tab-link{
        height: 30px;
        max-width: 83px;
        margin: initial;
        font-size: 14px;
    }
    .body--content-wp .image--ct-value,
    .body--content-wp .content--ct-value{
        max-width: 100%;
        flex: 0 0 100%;
    }
    .body--content-wp .content--ct-value{
        padding: 0;
    }
    .body--content-wp .image--ct-value{
        border-radius: 12px;
        margin-bottom: 35px;
    }
    .body--content-wp .image--ct-value img{
        width: 100%;
        max-height: 155px;
        object-fit: cover;
    }
    .content--ct-value .head--top{
        border-bottom: 3px solid #fff;
        padding-bottom: 12px;
        margin-bottom: 10px;
    }
    .content--ct-value .title--dt-head{
        font-size: 20px;
    }
    .content--ct-value .des--txt-i{
        font-size: 14px;
    }
    .content--ct-value .txt--content-i{
        font-size: 11px;
        line-height: 2;
    }


    .item--news{
        padding: 12px 12px;
    }
    .item--news .date--cat li{
        margin-bottom: 3px;
    }
    .item--news .image-n{
        max-width: 115px;
        flex: 0 0 115px;
    }
    .item--news .content-n{
        max-width: calc(100% - 115px);
        flex: 0 0 calc(100% - 115px);
        font-size: 14px;
    }
    .item--news .content--text .txt-i{
        padding-left: 0;
        max-width: calc(100% );
        flex: 0 0 calc(100%);
        
    }
    .item--news .content--text .icon-i{
        display: none;
        
    }
    .item--news .content--text .icon-i img{
        width: 20px;
    }
    .lp--whatIs .image--whatIs{
        padding-bottom: 270px;
    }
    .item--news .date--cat{
        margin-bottom: 5px;
    }
    .item--news .image-n img{
        height: 110px;
    }
    .item--news .content--text .txt-i .text-title{
        /* display: none; */
        font-size: 11.5px;
        line-height: 17px;
        font-weight: 600;
    }
    .item--news .content--text .txt-i .text-excerpt{
        margin-top: 7px;
        font-size: 10px;
        line-height: 17px;
    }
    .button--viewmore a{
        font-size: 12px;
        width: 110px;
        height: 31px;
    }
}
@media (max-width: 430px){
    .scrollSP,
    .scrollSP span {
        display: block;
    }
    .loading--item{
        top: -4px;
    }
    .loading--logo .logo-loading{
        margin: 0 100px;
    }
    .loading--logo .text--logo{
        width: 180px;
        margin-top: 25px;
    }
    .h2-title--head{
        font-size: 20px;
    }
    .bg--all-page{
        background-size: 200%;
    }
    .top--active .bg--all-page{
        background-position: center bottom;
    }
    .image--banner-what{
        max-height: 340px;
        min-height: 340px;
    }
    .bg--all-page .banner--top .image--bg img{
        width: 605px !important;
        left: 50%;
        transform: translateX(-50%);
        max-width: initial;
        /* clip-path: circle(75% at 50% 0); */
    }
    .top--active .banner--top .image--bg img{
        top: 55px;
    }
    .image--banner-what .image--mac img{
        max-width: calc(100% + 44px);
        left: -22px;
        position: relative;
    }
    .top--active .banner--bottom {
        top: calc(1px + 370px);
    }
    .banner--bottom .titleH1,
    .banner--bottom .titleH1 span{
        font-size: 26px !important;
    }
    .image--banner-feature::before{
        top: -70px;
        height: 500px;
    }
    .banner--bottom .banner--btn a{
        height: 30px;
        max-width: 100px;
        font-size: 12px;
        border-radius: 3px;
    }
    .banner--bottom .description-txt{
        max-width: calc(100% - 60px);
    }
    .lp--whatIs{
        padding-top: 78px;
    }
    .lp--whatIs .What--bottom-txt{
        padding-bottom: 100px;
    }
    .lp--whatIs .What--bottom-txt .text--txt{
        font-size: 13.5px;
        line-height: 30px;
    }
    .lp--whatIs .image--whatIs{
        padding-bottom: 350px;
    }
    .lp--whatIs .image--whatIs img{
        left: -18px;
        max-width: calc(100% + 36px);
        position: relative;
    }
    
    .all-active.active-whatIs .lp--whatIs .image--whatIs{
        bottom: -20px;
    }
    .all-active.active-whatIs .lp--whatIs .What--bottom-txt{
        bottom: 0;
        left: 0;
        max-width: 100%;
    }
    .active-whatIs .bg--all-page .image--banner-what img{
        top: -16%;
    }

    .slider--feature .item--li{
        padding: 10px 7px;
    }
    .item-feature{
        max-width: 245px;
        min-width: 245px;
    }
    .lp--whatIs .h2--feature{
        margin-bottom: 13px;
    }

    .title--head-option2 .h2--value{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .title--head-option2 .text--txt{
        font-size: 12px;
        text-align: left;
        padding-left: 9px;
        border-left: 2px solid #fff;
    }
    .active-value.active-value-detail .title--head-value {
        bottom: calc(100% - 115px);
    }
    .value--detail{
        height: calc(100% - 135px);
        padding: 19px 20px 65px;
    }
    .content--ct-value .head--top{
        border-bottom: 1px solid #fff;
        padding-bottom: 7px;
        margin-bottom: 3px;
    }
    .content--ct-value .des--txt-i{
        font-size: 11px;
    }
    .value--detail .body--form .tab-wrapper{
        position: relative;
        top: -5px;
        left: auto;
    }
    .value--detail .body--form .content-wrapper{
        height: calc(100% - 45px);
    }
    .body--content-wp .image--ct-value{
        margin-bottom: 0;
    }
    .content--ct-value .title--dt-head{
        margin-bottom: 4px;
        font-size: 18px;
    }
    .active-news.active-news-detail .title--head-news{
        top: calc(1px + 80px);
    }

    .item--news .content--text .txt-i{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        line-height: 20px;
    }
    .news--detail .main--xBody{
        padding: 45px 20px 10px;
    }
    .block-scroll--whatIs, .block-scroll--feature, .block-scroll--value, .block-scroll--value-detail, .block-scroll--news, .block-scroll--news-detail, .block-scroll--news-end{
        display: none;
    }
    .image--banner-news{
        align-items: flex-start;
    }
    .active-news.active-news-detail .image--banner-news img{
        top: 14%;
        height: 41%;
    }


    .scrollSP{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.8s cubic-bezier(0, 0.62, 0, 0.99);
        z-index: 105;
    }
    .scrollSP.scrollEC,
    .scrollSP.scroll--feature-t.scroll--what,
    .scrollSP.scroll--feature-v,
    .scrollSP.scroll--value-t{
        top: -250px;
    }
    .scrollSP.scroll--what-t,
    .scrollSP.scroll--value-b,
    .scrollSP.scroll--news-b{
        bottom: -250px;
        width: 100%;
        max-width: 100%;
        padding-bottom: 8px;
        background-color: #000000;
        height: auto;
    }
    .active-whatIs .scrollSP.scrollEC,
    .active-feature .scrollSP.scroll--feature-t.scroll--what,
    .active-value-detail .scrollSP.scroll--feature-v,
    .active-news-detail .scrollSP.scroll--value-t{
        top: 0;
        opacity: 1;
        visibility: visible;
    }
    .top--active .scrollSP.scroll--top-b,
    .active-whatIs .scrollSP.scroll--what-t,
    .active-feature .scrollSP.scroll--value-b,
    .active-value-detail .scrollSP.scroll--news-b{
        bottom: 0;
        opacity: 1;
        visibility: visible;
    }

    .scrollSP.scroll--top-b{
        bottom: -250px;
        width: 100%;
        max-width: 100%;
        padding-bottom: 8px;
        height: auto;
        color: #000000;
    }

}


@media (max-width: 1024px) and 
       (min-width: 768px){
    .loading--all-page{
        overflow: hidden;
    }
    .loading--all-page .block-bottom{
        display: flex;
    }
    .loading--all-page .block-bottom img{
        margin-top: auto;
    }
    @media (min-height: 966px) {
        .item-feature{
            max-width: 380px;
            min-width: 380px;
        }
        .item-feature .image--item img{
            height: 780px;
            object-fit: cover;
            border-radius: 12px;
        }
        
    }
}
@media (max-width: 430px) {
    @media (max-height: 630px) {
        .image--banner-what {
            max-height: 240px;
            min-height: 240px;
        }
        .image--banner-what .image--mac img{
            left: auto;
            width: 100%;
        }
        .item-feature .image--item img{
            height: 330px;
            object-fit: cover;
            width: 100%;
            border-radius: 10px 10px 10px 10px;
        }
        .lp--whatIs .What--bottom-txt{
            padding-bottom: 70px;
        }
        .lp--whatIs .What--bottom-txt .text--txt{
            margin-bottom: 0;
            font-size: 11px;
        }
        .all-active.active-whatIs .lp--whatIs .image--whatIs{
            bottom: -115px;
        }
        .top--active .banner--bottom {
            top: calc(1px + 270px);
        }
    }
    @media (min-height: 740px) {
        .image--banner-what {
            max-height: 380px;
            min-height: 380px;
        }
        .top--active .banner--bottom{
            top: calc(1px + 420px);
        }
        .item-feature{
            max-width: 265px;
            min-width: 265px;
        }
        /* .item-feature .image--item img{

        } */
    }
    
}









