.searchContainer {
  display: flex;
}
.searchContainer > div {
  flex: 1;
}
button.btnSearch {
  min-width: 4.0625rem;
  width: 4.0625rem;
  height: 4.0625rem;
  border-radius: 50%;
  padding: 0px;
}
